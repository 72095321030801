import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import SelectCustom from '../SelectCustom';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext, ProtocoloValidateContext } from '../../App';
import InputCustom from '../InputCustom';
import { ReactComponent as TrashIcon} from '../../assets/imgs/trashIcon.svg'
import InputNumberCustom from '../InputNumberCustom';

interface ItemProps {
  item: ProtocoloItem
  itemIndex: number
  faseIndex: number
  inputChange: any
  handleDeleteItem: any
  vacCombo: VacinaCombo[]
}

export default function ProtocoloItem(props: ItemProps) {
  const { item: {itemTipo, itemQtd, itemDesc, vacCodigo }, inputChange, itemIndex, faseIndex, vacCombo, handleDeleteItem } = props
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const { protocoloIsValid } = useContext(ProtocoloValidateContext);
  const [selectedAcao, setSelectedAcao] = useState<string>("");

  const itemTipoEnum = useMemo(() => translations ? [
    "medicamento", "implante", "remover implante", "iatf"
  ] : [], [translations]);

  useEffect(() => {
    const languageParam = searchParams.get('language') || 'pt'
    setLanguage(languageParam)
  }, [searchParams, setLanguage]);

  useEffect(() => {
    const acao = searchParams.get('acao') || "novo"
    setSelectedAcao(acao)
  }, [searchParams, selectedAcao]);

  return (
    <Grid item container xs={12} mt={2}>
      <Grid item container xs={3} pr={4} flexWrap="nowrap">
        { selectedAcao !== "visualizar" &&
          <Grid item mr={1} alignSelf="end">
              <div className="trash-button" onClick={() => handleDeleteItem(faseIndex, itemIndex)} ><TrashIcon /></div>
          </Grid>
        }
        <Grid item xs>
          <SelectCustom
              placeholder={translations["lbl.react.protocolo.tipo"]}
              handleChange={(event: any) => inputChange(event, itemIndex, faseIndex)}
              items={[
                {value: translations["lbl.react.protocolo.medicamento"], valueKey: "0"},
                {value: translations["lbl.react.protocolo.implante"], valueKey: "1"},
                {value: translations["lbl.react.protocolo.remover.implante"], valueKey: "2"},
                {value: translations["lbl.react.protocolo.item.iatf"], valueKey: "3"}
              ]}
              selectedItem={itemTipo}
              name="itemTipo"
              isValid={itemTipo !== "" || protocoloIsValid}
              readOnly={selectedAcao === "visualizar" ? true : false}
            />
        </Grid>
      </Grid>
      { !["iatf"].includes(itemTipoEnum[parseInt(itemTipo)]) &&
        <Grid item xs={4} pr={4}>
          <InputCustom
            placeholder={translations["lbl.react.protocolo.descricao"]}
            handleChange={(event: any) => inputChange(event, itemIndex, faseIndex)}
            controlledValue={itemDesc}
            name="itemDesc"
            readOnly={selectedAcao === "visualizar" ? true : false}
          />
        </Grid>
      }
      { !["iatf", "remover implante"].includes(itemTipoEnum[parseInt(itemTipo)]) &&
        <Grid item xs={4} pr={4}>
          <SelectCustom
            placeholder={translations["lbl.react.protocolo.produto"]}
            handleChange={(event: any) => inputChange(event, itemIndex, faseIndex)}
            items={vacCombo}
            selectedItem={vacCodigo}
            name="vacCodigo"
            isValid={(vacCodigo !== "" && vacCodigo !== "0") || protocoloIsValid}
            readOnly={selectedAcao === "visualizar" ? true : false}
          />
        </Grid>
        
      }
      { !["iatf", "remover implante"].includes(itemTipoEnum[parseInt(itemTipo)]) &&
        <Grid item xs={1} className="item-qtd">
          <InputNumberCustom
            placeholder={translations["lbl.react.protocolo.quant"]}
            handleChange={(event: any) => inputChange(event, itemIndex, faseIndex)}
            controlledValue={itemQtd}
            name="itemQtd"
            isValid={(itemQtd !== null && itemQtd !== 0) || protocoloIsValid}
            readOnly={selectedAcao === "visualizar" ? true : false}
          />
        </Grid>
      }
    </Grid>
  )
}