import dayjs from "dayjs";
import React, { useContext, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR, es } from 'date-fns/locale';
import { LanguageContext } from '../App';
import { ReactComponent as CalendarIcon } from '../assets/imgs/calendarIcon.svg'
import { Grid } from "@mui/material";
registerLocale('pt', ptBR);
registerLocale('es', es)

interface SelectProps {
  handleChange?: any,
  handleClear?: any,
  handleClickOutside?: any,
  placeholder: string,
  startDate: Date | null,
  endDate: Date | null,
  onlyMonths: boolean | undefined,
  dateFormat: string,
  limitMaxDate: boolean,
  selectRange?: boolean,
  readOnly?: boolean,
  isValid?: boolean;
}

export default function DataPickerCustom(props: SelectProps) {
  const {
    handleChange,
    handleClear,
    handleClickOutside,
    startDate,
    endDate,
    placeholder,
    onlyMonths,
    dateFormat,
    limitMaxDate,
    selectRange = true,
    readOnly = false,
    isValid = true
  } = props;
  const { translations, language } = useContext(LanguageContext);
  const [ calendarState, setCalendarState ] = useState<boolean | undefined>(false);

  const mesEnum = translations ? [
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [];
  
  const handleValue = () => {
    const startDay = startDate ? startDate.getDate() : ""
    const startMonth = startDate ? mesEnum[startDate.getMonth()] : ""
    const startYear = startDate ? startDate.getFullYear() : ""
    const endDay = endDate ? endDate.getDate() : ""
    const endMonth = endDate ? mesEnum[endDate.getMonth()] : ""
    const endYear = endDate ? endDate.getFullYear() : ""
    if (startDate && onlyMonths) return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
    else if (startDate && !selectRange) return `${startDay} ${startMonth} ${startYear}`
    else if (startDate) return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`
    else return ""
  }

  const handleMaxDate = () => {
    const localStartDate = !startDate ?  dayjs().toDate() : startDate
    const maxPickabledDate = dayjs().add(364, 'days').toDate()
    const maxDate = dayjs(localStartDate).add(364, 'days').toDate()

    const today = dayjs().toDate()
    if (maxDate > today && limitMaxDate) return today
    if (maxDate > maxPickabledDate) return maxPickabledDate
    else return maxDate
  }

  const handleOpenCalendar = () => {
    handleClear()
    setCalendarState(true)
  }

  const handleInternalChange = ([newStartDate, newEndDate]: [newStartDate: Date, newEndDate: Date]) => {
    if (!selectRange) setCalendarState(false)
    if (newEndDate !== null) setCalendarState(false)
    handleChange([newStartDate, newEndDate])
  }

  const handleInternalClear = () => {
    handleClear()
  }

  const handleInternalClickOutside = () => {
    setCalendarState(false)
    handleClickOutside()
  }

  const handleInputClick = () => {
    if (!selectRange) handleClear()
    setCalendarState(true)
  }

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <div className="select-custom_icon" onClick={readOnly ? undefined : handleOpenCalendar}>
        <CalendarIcon />
      </div>
      <DatePicker
        readOnly={readOnly}
        className={`select-custom_date ${isValid ? '' : 'invalid'}`}
        placeholderText={placeholder}
        selected={null}
        onChange={handleInternalChange}
        open={calendarState}
        onInputClick={readOnly ? undefined : handleInputClick}
        selectsRange
        startDate={startDate}
        onCalendarOpen={handleInternalClear}
        onClickOutside={handleInternalClickOutside}
        endDate={endDate}
        dateFormat={dateFormat}
        showMonthYearPicker={onlyMonths}
        locale={language}
        value={handleValue()}
        minDate={null}
        maxDate={handleMaxDate()}
      />

    </Grid>
  );
};