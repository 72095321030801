import * as React from 'react';
import { styled } from '@mui/system';
import { TablePagination } from '@mui/base/TablePagination';
import '../../assets/css/tablePagination.scss';
import { LanguageContext } from '../../App';
import { Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface GenericTablePaginationProps {
  tableHeads: (string | number)[] | [];
  tableUpperHeads?: (string | number)[] | [];
  tableRows: Array<Array<string | number>>;
  tableHeadsTooltips?: {[key: number]: string};
  tableTotalizerTooltips?: {[key: number]: string};
  expandToPrint?: boolean;
  tableTotalizer?: Array<Array<string | number>>;
  valueBorderRight?: number[];
  indexValues?: boolean;
}

export default function GenericTablePagination(props: GenericTablePaginationProps) {
  const { indexValues = false } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const { translations } = React.useContext(LanguageContext);
  const [tableHeads, setTableHeads] = React.useState<(string | number)[] | []>([]);
  const [tableRows, setTableRows] = React.useState<Array<Array<string | number>>>([]);
  const keyForMap = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  const valueBorderRight: number[] | [] = props.valueBorderRight ? props.valueBorderRight : props.tableRows[0].map((_value, index) => index);
  
  React.useEffect(() => {
    const fixTooltips = () => {
      let container = document.getElementsByClassName('MuiTablePagination-actions');
      if (container.length > 0) {
        const previousButton = container[0].firstElementChild as HTMLElement;
        const nextButton = previousButton.nextSibling as HTMLElement;
        previousButton.title = translations["lbl.react.table.pag.anterior"];
        nextButton.title = translations["lbl.react.table.proxima.pag"];
      }
    }

    props.expandToPrint ? setRowsPerPage(-1) : setRowsPerPage(5);
    setTableHeads(props.tableHeads);
    setTableRows(props.tableRows);
    fixTooltips();
}, [ props.tableHeads, props.tableRows, props.expandToPrint, translations])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid sx={{ maxWidth: '100%', minWidth: 500 }}>
      <table aria-label="custom pagination table" className='table-pagination'>
        <thead>
          { props.tableUpperHeads ? (<tr className="table-upper-head-container"> {
            props.tableUpperHeads.map((item: any, index: any) => {
              const key = item.toString() + index + keyForMap;

              if (index === 0) {
                return <th className="table-upper-head br row-first-value" key={key}>{item}</th>;
              } else if (valueBorderRight.includes(index)) {
                if (props.tableTotalizerTooltips) {
                  return <th className="br" key={key}>{item.length > 0 ? item : <>&nbsp;</> }</th>;
                } else {
                  return <th key={key}>{item.length > 0 ? item : <>&nbsp;</> }</th>;
                }
              } else {
                return <th key={key}>{item.length > 0 ? item : <>&nbsp;</> }</th>;
              }
            })
          }
          </tr>) : null }
          <tr>
          {tableHeads.map((item: string | number, index: any) => {
            const key = item.toString() + index + keyForMap;
            
            if (props.tableHeadsTooltips !== undefined) {
              const titleTooltip = props.tableHeadsTooltips[index];

              if (valueBorderRight.includes(index)) {
                return <Tooltip key={key} title={titleTooltip}><th className="br">{item}</th></Tooltip>;
              } else {
                if (valueBorderRight.includes(index)) {
                  return <Tooltip className="br" key={key} title={titleTooltip}><th>{item}</th></Tooltip>;
                } else {
                  return <Tooltip key={key} title={titleTooltip}><th>{item}</th></Tooltip>;
                }
              }
            } else {
              if (valueBorderRight.includes(index)) {
                return <th className="br" key={key}>{item}</th>;
              } else {
                return <th key={key}>{item}</th>;
              }
            }
          })}
          </tr>
        </thead>
        <tbody>
          {(rowsPerPage > 0
            ? tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : tableRows
          ).map((row: (string | number)[]) => (
            <tr key={String(row[0]) + keyForMap}>
              {row.map((value: string | number, index: number) => {
                const key = index + String(value) + keyForMap;
                if (index === 0) {
                  if (valueBorderRight.includes(index)) {
                    return (<td className='row-first-value br' key={key}>{indexValues && tableRows.length > 8 ? tableRows.findIndex((element: any) => element[0] === value) + 1 + ". " + value : value}</td>);
                  } else {
                    return (<td className='row-first-value' key={key}>{indexValues && tableRows.length > 8 ? tableRows.findIndex((element: any) => element[0] === value) + 1 + ". " + value : value}</td>);
                  }
                } else {
                  if (valueBorderRight.includes(index)) {
                    return (<td className="br" key={key}>{value}</td>);
                  } else {
                    return (<td key={key}>{value}</td>);
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {
            props.tableTotalizer ? (
              <tr className='table-totalizer-container'>
                { 
                  props.tableTotalizer.map((item: any, index: any) => {
                    if (index === 0) {
                      return <th className="table-totalizer-value br" key={item.toString() + index + keyForMap}>{item}</th>;
                    } else if ([3, 6].includes(index)) {
                      if (props.tableTotalizerTooltips) {
                        return <th className="table-totalizer-value br" key={item.toString() + index + keyForMap}><span>
                          {item} <Tooltip title={props.tableTotalizerTooltips[index]}><InfoIcon className='info-icon' fontSize='small'/></Tooltip>
                          </span></th>;
                      } else {
                        return <th className="table-totalizer-value" key={item.toString() + index + keyForMap}>{item}</th>;
                      }
                    } else if (index === 9) {
                      if (props.tableTotalizerTooltips) {
                        return <th className="table-totalizer-value" key={item.toString() + index + keyForMap}><span>
                          {item} <Tooltip title={props.tableTotalizerTooltips[index]}><InfoIcon className='info-icon' fontSize='small'/></Tooltip>
                          </span></th>;
                      } else {
                        return <th className="table-totalizer-value" key={item.toString() + index + keyForMap}>{item}</th>;
                      }
                    } else {
                      return <th className="table-totalizer-value" key={item.toString() + index + keyForMap}>{item}</th>;
                    }
                  })
                }
              </tr>
            ) : null
        }
          <tr className="tfootPagination">
            <CustomTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={tableHeads.length}
              count={tableRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={translations["lbl.react.table.linhas.por.pag"]}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + " " + translations["lbl.react.table.de"] + " " + count + " " + translations["lbl.react.table.linhas"]
                }
              }
            />
          </tr>
        </tfoot>
      </table>
    </Grid>
  );
}

const CustomTablePagination = styled(TablePagination)`${require('../../assets/css/tablePagination.scss').toString()}`;
