import React, { useCallback, useEffect, useState, useContext } from 'react';
import dayjs from "dayjs";
import Tabs from '../components/Tabs';
import Table from '../components/Table';
import { getDescartes, postDescartes, putDescartes } from '../apis/apiDescartes';
import fieldsTables from '../utils/fieldsTables';
import { Button, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import EmptyState from '../components/EmptyState';
import MultilineTextFields from '../components/MultilineTextFields';
import Loading from '../components/Loading';
import { LanguageContext } from '../App';
import DataPickerCustom from '../components/DataPicker';

interface Animal {
  idVisual: string ;
  idEletronico: string | null;
  dataNascimento: string;
  prevParto: string;
  idadeMeses: number;
  pesoNascimento: number;
  aniSexo: string;
  aniNumeroCab: string;
  categoria: {
      catCodigo: number;
      catNome: string;
  };
  raca: {
      espCodigo: number;
      espNome: string;
      racCodigo: number;
      racNome: string;
  };
  descartes: {
    des_codigo: number;
    ani_codigo: number;
    desDataIni: string;
    desDataFim: string;
    desObsIni: string;
    desObsFim: string;
    proCodigo: number;
  }[] | [] 
}

function Descarte() {
  const { translations, setLanguage } = useContext(LanguageContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<string | null>("");
  const [searchParams] = useSearchParams();
  const [cullingDate, setCullingDate] = useState<Date>(new Date());
  const [cullingCompletionDate, setCullingCompletionDate] = useState(new Date());
  const [cullingObservation, setCullingObservation] = useState<string>("");
  const [cullingCompletionObservation, setCullingCompletionObservation] = useState<string>("");
  const [minCullingDate, setMinCullingDate] = useState<Date | null>(null);
  const [minCullingCompletionDate, setMinCullingCompletionDate] = useState<Date | null>(null);
  const [animalsToFlag, setAnimalsToFlag] = useState<Animal[] | []>([]);
  const [animalsToFinish, setAnimalsToFinish] = useState<Animal[] | []>([]);

  const handleChangeCullingDataPicker = (date: Date) => {
    setCullingDate(date);
  };
  
  const handleChangeCullingCompletionDataPicker = (date: Date) => {
    setCullingCompletionDate(date);
  }

  const handleDateFormatPostgres = (date: Date | undefined) => {
    const day = date ? date.getDate() : "";
    const month = date ? date.getMonth() : "";
    const year = date ? date.getFullYear() : "";
    
    if (date) return `${year}-${String((typeof month === 'number' ? month + 1 : month)).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    else return "";
  }

  const handleBackButton = () => {
    window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal`;
  }

  const handleSubmit = async (submitType: string) => {
    const token = searchParams.get('token');
    
    if (submitType === "sinalizar") {
      const postBody = {
        proCodigo: searchParams.get('pro_codigo'),
        descartes: animalsToFlag.map((animal: any) => ({
          aniCodigo: animal.aniCodigo,
          desDataIni: handleDateFormatPostgres(cullingDate),
          desObsIni: cullingObservation
        }))
      }

      await postDescartes(postBody, token).then(() => {
        window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal`;
      });
    } else {
      const putBody = {
        proCodigo: searchParams.get('pro_codigo'),
        descartes: animalsToFinish.map((animal: any) => ({
          desCodigo: animal.desCodigo,
          aniCodigo: animal.aniCodigo,
          desDataIni: animal.desDataIni,
          desObsIni: animal.desObsIni,
          desDataFim: handleDateFormatPostgres(cullingCompletionDate),
          desObsFim: cullingCompletionObservation
        }))
      }
      
      await putDescartes(putBody, token)
      .then(() => {
        window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal`;
      });
    }
  }

  const handleCloseTable = (name: string) => {
  };

  const handleChangeCullingObservation = (event: any) => {
    setCullingObservation(event.target.value);
  };

  const handleChangeCullingCompletionObservation = (event: any) => {
    setCullingCompletionObservation(event.target.value);
  };
  
  const handleDeleteRowFlag = (id: number) => {
    setAnimalsToFlag(animalsToFlag.filter((animal: any) => animal.aniCodigo !== id));
  };
  
    const handleDeleteRowFinish = (id: number) => {
      setAnimalsToFinish(animalsToFinish.filter((animal: any) => animal.aniCodigo !== id));
    };

  const getLatestDate = (animals: any) => {
    let maxDate: Date | null = null;

    animals.forEach((animal: any) => {
        if (animal.desDataIni && dayjs(animal.desDataIni).isValid()) {
            const iniDate = dayjs(animal.desDataIni).toDate();
            if (!maxDate || iniDate > maxDate) {
                maxDate = iniDate;
            }
        }

        if (animal.desDataFim && dayjs(animal.desDataFim).isValid()) {
            const endDate = dayjs(animal.desDataFim).toDate();
            if (!maxDate || endDate > maxDate) {
                maxDate = endDate;
            }
        }
    });

    return maxDate;
  }

  const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
    setSelectedTab(value);
  };

  const findLastCullingCod = (descartes: any) => {
    let lastCullingCode: number = 0;
    let lastCullingData: string = "";
    for (let descarte of descartes) {
      if (!lastCullingData || dayjs(descarte.desDataIni).toDate() > dayjs(lastCullingData).toDate()) {
        lastCullingData = descarte.desDataIni;
        lastCullingCode = descarte.desCodigo;
      }
    }

    return lastCullingCode !== 0 ? descartes.find((descarte: any) => descarte.desCodigo === lastCullingCode) : null;
  };
  
  const handleGetAnimals = useCallback(async () => {
    setLoading(true);

    const animalFormatterToTable = (animal: any) => {
      const ultimoDescarte = findLastCullingCod(animal.descartes);
  
      return {
        desCodigo: ultimoDescarte && ultimoDescarte.desCodigo,
        aniCodigo: animal.aniCodigo,
        idVisual: animal.idVisual,
        lote: animal.lote,
        localidade: animal.campo,
        categoria: animal.categoria,
        pesoAtual: animal.peso,
        desObsIni: ultimoDescarte ? ultimoDescarte.desObsIni : "",
        desObsFim: ultimoDescarte ? ultimoDescarte.desObsFim : "",
        desDataIni: ultimoDescarte ? ultimoDescarte.desDataIni : "",
        desDataFim: ultimoDescarte ? ultimoDescarte.desDataFim : "",
        desStatus: !ultimoDescarte || ultimoDescarte.desDataFim.length > 0 ? "regular" : "descartado",
      }
    };

    const params = {
      proCodigo: searchParams.get('pro_codigo'),
      aniCodigos: searchParams.get('aniCodigos')
    }
    const token = searchParams.get('token');
    
    const result = await getDescartes(params, token);

    if (result && result.status === 200) {
      const mappedAnimalsData: any = result.data.map((animal: any) => animalFormatterToTable(animal));
      const mappedRegularAnimalsData = mappedAnimalsData.filter((animal: any) => animal.desStatus === "regular");
      const mappedCullAnimalsData = mappedAnimalsData.filter((animal: any) => animal.desStatus === "descartado");

      
      setAnimalsToFlag(mappedRegularAnimalsData);
      setAnimalsToFinish(mappedCullAnimalsData);
      setSelectedTab(mappedRegularAnimalsData.length > 0 || mappedCullAnimalsData.length === 0 ? 'sinalizar' : 'finalizar');
    }

    setLoading(false);
  }, [searchParams]);

  useEffect(() => {
    const languageParam = searchParams.get('language') || 'pt';

    setLanguage(languageParam);
    handleGetAnimals();
  }, [searchParams, setLanguage, handleGetAnimals]);

  useEffect(() => {
    if (animalsToFlag) {
      const latestCullingDate = getLatestDate(animalsToFlag);
      
      animalsToFlag.length > 0 ? setMinCullingDate(latestCullingDate) : setMinCullingDate(null);
    }
    if (animalsToFinish) {
      const latestCullingCompletionDate = getLatestDate(animalsToFinish);

      animalsToFinish.length > 0 ? setMinCullingCompletionDate(latestCullingCompletionDate) : setMinCullingCompletionDate(null);
    }
  }, [animalsToFlag, animalsToFinish]);

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title">
              { translations["lbl.react.lista.de.animais"] }
            </span>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item container xs={12} justifyContent={"space-between"} flexDirection={"row"}>
                <Grid item xs={6} justifyContent={"space-between"} flexDirection={"row"}>
                  { selectedTab &&
                    <Tabs handleChange={handleChangeTab} value={selectedTab} labels={[{ label: translations["lbl.react.tab.sinalizar.descarte"].toUpperCase(), name: "sinalizar" }, { label: translations["lbl.react.tab.finalizar.descarte"].toUpperCase(), name: "finalizar" }]}/>
                  }
                </Grid>
                <Grid item xs={6} alignSelf={"center"} textAlign={"end"} flexDirection={"row"}>
                  <Button onClick={() => handleBackButton()} className="btn-secundario btn-voltar" variant="contained"><ArrowBackIcon /> { translations["lbl.react.voltar.para.lista.de.animais"] }</Button>
                </Grid>
              </Grid>
              { loading === false ?
                <Grid item xs={12}>
                  {selectedTab === "sinalizar" && (
                    animalsToFlag && animalsToFlag.length ?
                      <div>
                        <Grid container item xs={12}>
                          <Grid container item xs={12} alignItems={"center"}>
                            <Grid item xs={2} marginLeft={"8px"} justifyContent={"flex-end"} textAlign={"end"} paddingRight={2}>
                              <p>*{translations["lbl.react.data"]}</p>
                            </Grid>
                            <Grid item xs={3}>
                              <DataPickerCustom placeholder={translations["lbl.react.data.sinalizar"]} handleChange={handleChangeCullingDataPicker} minimumDate={minCullingDate} maximumDate={new Date()} dateFormat="dd/MM/yyyy" onlyMonths={false} currentValue={cullingDate} />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} alignItems={"start"}>
                            <Grid item xs={2} justifyContent={"flex-end"} textAlign={"end"} paddingRight={1}>
                              <p>{translations["lbl.react.observacao"]}</p>
                            </Grid>
                            <Grid item xs={10} paddingRight={1}>
                              <MultilineTextFields handleChange={handleChangeCullingObservation} width="100%" currentValue={cullingObservation} />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} marginTop={2} className='banner-alert' flexDirection={"column"}>
                            <WarningIcon className='banner-alert_icon' color='warning'/>
                            <p><strong>{translations["lbl.react.atencao"]}</strong></p>
                            <p><span>{`${animalsToFlag.length > 1 ? translations["lbl.react.existem"] : translations["lbl.react.existe"]} ${animalsToFlag.length} ${animalsToFlag.length > 1 ? translations["lbl.react.animais"].toLowerCase() : translations["lbl.react.animal"].toLowerCase()} (${translations["lbl.react.id"]}: `}</span><strong>{`${animalsToFlag.map((animal: any, index: number) => (index === 0 ? "" : " ") + animal.idVisual)}`}</strong><span>{`) ${animalsToFlag.length > 1 ? translations["lbl.react.que.serao.afetados.nesta.operacao"] : translations["lbl.react.que.sera.afetado.nesta.operacao"]}`}</span></p>
                           {animalsToFinish && animalsToFinish.length > 0 && (<p><span>{`${animalsToFinish.length > 1 ? translations["lbl.react.outros"] : translations["lbl.react.outro"]} ${animalsToFinish.length} ${animalsToFinish.length > 1 ? translations["lbl.react.animais"].toLowerCase() : translations["lbl.react.animal"].toLowerCase()} (${translations["lbl.react.id"]}: `}</span><strong>{`${animalsToFinish.map((animal: any, index: number) => (index === 0 ? "" : " ") + animal.idVisual)}`}</strong><span>{`) ${animalsToFinish.length > 1 ? translations["lbl.react.nao.serao.sinalizados.como.descarte.pois"] : translations["lbl.react.nao.sera.sinalizado.como.descarte.pois"]}`}</span></p>)}
                          </Grid>
                          <Grid container item xs={12} marginTop={2}>
                            <Grid item xs={2} paddingRight={1}>
                            </Grid>
                            <Grid item xs={10} paddingLeft={1}>
                              <Button onClick={() => handleSubmit("sinalizar")} className="btn-primario" variant="contained">{ translations["lbl.react.tab.sinalizar.descarte"] }</Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Table
                            columns={fieldsTables(translations, handleDeleteRowFlag).descartes}
                            rows={Object.values(animalsToFlag)}
                            idColumn='aniCodigo'
                            sortColumn='idVisual'
                            printButton={false}
                            hasCheckbox={false}
                            tableTitle={
                              <Grid item container alignItems={"center"} marginBottom={2}>
                                <strong>
                                  {translations["lbl.react.dados.informacoes.selecionadas"]}
                                </strong> 
                                <Tooltip title={translations["lbl.react.tooltip.apenas.animais.aptos.serem.sinalizados.descarte"]}>
                                  <InfoIcon className='info-icon' fontSize='small' style={{marginLeft: '5px'}} />
                                </Tooltip>
                              </Grid>
                            }
                            tableSubTitle={
                              <Grid alignItems={"end"}>
                                {translations["lbl.react.animais.selecionados.descarte"]}: <strong>{animalsToFlag.length}</strong> {translations["lbl.react.table.de"]} {animalsToFlag.length + animalsToFinish.length} {translations["lbl.react.animais"].toLowerCase()}
                              </Grid>
                            }
                            handleCloseTable={handleCloseTable}
                            name="sinalizar"
                          />
                        </Grid>
                      </div>
                    :
                      <div>
                        <EmptyState emptyTab={true} />
                      </div>
                    )
                  }
                  {selectedTab === "finalizar" && (
                    animalsToFinish && animalsToFinish.length ?
                      <div>
                        <Grid container item xs={12}>
                          <Grid container item xs={12} alignItems={"center"}>
                            <Grid item xs={2} marginLeft={"8px"} justifyContent={"flex-end"} textAlign={"end"} paddingRight={2}>
                              <p>*{translations["lbl.react.data"]}</p>
                            </Grid>
                            <Grid item xs={3}>
                              <DataPickerCustom placeholder={translations["lbl.react.data.finalizar"]} handleChange={handleChangeCullingCompletionDataPicker} minimumDate={minCullingCompletionDate} maximumDate={new Date()} dateFormat="dd/MM/yyyy" onlyMonths={false} currentValue={cullingCompletionDate} />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} alignItems={"start"}>
                            <Grid item xs={2} justifyContent={"flex-end"} textAlign={"end"} paddingRight={1}>
                              <p>{translations["lbl.react.observacao"]}</p>
                            </Grid>
                            <Grid item xs={10} paddingRight={1}>
                              <MultilineTextFields handleChange={handleChangeCullingCompletionObservation} width="100%" currentValue={cullingCompletionObservation} />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} marginTop={2} className='banner-alert' flexDirection={"column"}>
                            <WarningIcon className='banner-alert_icon' color='warning'/>
                            <p><strong>{translations["lbl.react.atencao"]}</strong></p>
                            <p><span>{`${animalsToFinish.length > 1 ? translations["lbl.react.existem"] : translations["lbl.react.existe"]} ${animalsToFinish.length} ${animalsToFinish.length > 1 ? translations["lbl.react.animais"].toLowerCase() : translations["lbl.react.animal"].toLowerCase()} ${animalsToFinish.length > 1 ? translations["lbl.react.selecionados"].toLowerCase() : translations["lbl.react.selecionado"].toLowerCase()} (${translations["lbl.react.id"]}: `}</span><strong>{`${animalsToFinish.map((animal: any, index: number) => (index === 0 ? "" : " ") + animal.idVisual)}`}</strong><span>{`) ${animalsToFinish.length > 1 ? translations["lbl.react.que.serao.eliminados.da.lista"] : translations["lbl.react.que.sera.eliminado.da.lista"]}`}</span></p>
                          </Grid>
                          <Grid container item xs={12} marginTop={2}>
                            <Grid item xs={2} paddingRight={1}>
                            </Grid>
                            <Grid item xs={10} paddingLeft={1}>
                              <Button onClick={() => handleSubmit("finalizar")} className="btn-primario" variant="contained">{ translations["lbl.react.tab.finalizar.descarte"] }</Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Table
                            columns={fieldsTables(translations, handleDeleteRowFinish).descartes}
                            rows={Object.values(animalsToFinish)}
                            idColumn='aniCodigo'
                            sortColumn='idVisual'
                            printButton={false}
                            hasCheckbox={false}
                            tableTitle={
                              <Grid item container alignItems={"center"} marginBottom={2}>
                                <strong>
                                  {translations["lbl.react.dados.informacoes.selecionadas"]}
                                </strong> 
                                <Tooltip title={translations["lbl.react.tooltip.encontramse.listados.apenas.animais.ja.sinalizados"]}>
                                  <InfoIcon className='info-icon' fontSize='small' style={{marginLeft: '5px'}} />
                                </Tooltip>
                              </Grid>
                            }
                            tableSubTitle={
                              <Grid alignItems={"end"}>
                                {translations["lbl.react.animais.selecionados.finalizar.descarte"]}: <strong>{animalsToFinish.length}</strong> {translations["lbl.react.table.de"]} {animalsToFlag.length + animalsToFinish.length} {translations["lbl.react.animais"].toLowerCase()}
                              </Grid>
                            }
                            handleCloseTable={handleCloseTable}
                            name="finalizar"
                          />
                        </Grid>
                      </div>
                    :
                      <div>
                        <EmptyState emptyTab={true} />
                      </div>
                    )
                  }
                </Grid>
                :
                <Loading />
              }
            </Grid>
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default Descarte;
