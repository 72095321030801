import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Box, Button, Grid, Switch } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { LanguageContext, ProtocoloValidateContext } from '../App';
import AccordionCustom from '../components/ArcodionCustom';
import addCircleOutlineIcon from '../assets/imgs/addCircleOutlineIcon.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Fase from '../components/protocolo/Fase';
import { getProtocolo, postProtocolo, putProtocolo, updateProtocoloStatus } from '../apis/apiProtocolo';
import InputCustom from '../components/InputCustom';
import DataPickerCustom from '../components/RangeDataPicker';
import dayjs from 'dayjs';
import { getVacinas } from '../apis/apiVacina';

function NovoProtocolo() {
  const [loading, setLoading] = useState<boolean>(true)
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const { setProtocoloIsValid, protocoloIsValid } = useContext(ProtocoloValidateContext);
  const [protocolo, setProtocolo] = useState<Protocolo>();
  const [selectedAcao, setSelectedAcao] = useState<string>("");
  const [ fasesD, setFasesD ] = useState<number[]>([]);
  const [ hasModified, setHasModified ] = useState<boolean>(false);
  const [ vacinaCombo, setVacinaCombo ] = useState<VacinaCombo[]>([{
    value: '',
    valueKey: ''
  }]);

  useEffect(() => {
    setLoading(true)
    const languageParam = searchParams.get('language') || 'pt'
    const acao = searchParams.get('acao') || "novo"
    setLanguage(languageParam)
    setLoading(false)
    setSelectedAcao(acao)
  }, [searchParams, setLanguage]);

  const validateProtocolo = useCallback(() => {
    const validateUniqueFasesD = (new Set(fasesD)).size === fasesD.length;
    const validateFasesD = protocolo!.fases.every((fase: ProtocoloFase) => fase.faseD !== null && fase.faseD.toString() !== "" && typeof fase.faseD !== "string")
    const validateProtocoloInputs = (protocolo!.ptlNome.length > 0) && (protocolo!.ptlData !== "")
    const validateFase = protocolo!.fases.every((fase: ProtocoloFase) => fase.itens.every((item: ProtocoloItem) => {
      if (item.itemTipo !== "") {
        if (item.itemTipo.toString() === "0") {
          return ((item.vacCodigo !== "") && (item.itemQtd > 0))
        } else if (item.itemTipo.toString() === "1") {
          return item.vacCodigo !== ""
        } else {
          return true
        }
      } else {
        return false
      }
    }))

    const isValid = validateFasesD && validateProtocoloInputs && validateFase && validateUniqueFasesD

    if (!isValid) {
      setTimeout(() => {
        const firstInvalidElement = document.getElementsByClassName("invalid")[0];
        if (firstInvalidElement) {
          firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 300);
    }

    setProtocoloIsValid(isValid)

    return isValid
  }, [fasesD, protocolo, setProtocoloIsValid])

  const handleSaveProtocolo = useCallback(async () => {
    if (validateProtocolo() === true) {

      if (protocolo) {
        const token = searchParams.get('token')
        const language = searchParams.get('language') || 'pt'
        const proCodigo = searchParams.get('pro_codigo') || '';
        const ptlCodigo = searchParams.get('ptl_codigo') || "";

        const mappedData = {
          ...protocolo,
          proCodigo: proCodigo,
          ptlData: dayjs(protocolo.ptlData).format('DD/MM/YYYY')
        }

        let result: any = "";

        switch (selectedAcao) {
          case "novo":
            result = await postProtocolo(mappedData, token, proCodigo)
            break;
          case "editar":
            result = hasModified ? await putProtocolo(mappedData, token, proCodigo, ptlCodigo) : { status: 200 }
            break;
          case "copiar":
            result = await postProtocolo(mappedData, token, proCodigo)
            break;
          case "visualizar":
            result = await updateProtocoloStatus({ status: mappedData.ptlAtivo}, token, proCodigo, ptlCodigo)
            break;
          default:
            break;
        }

        if (result.status === 200) {
          window.location.href = `../listar/protocolo?pro_codigo=${proCodigo}&token=${token}&language=${language}`
        }
      }
    }     

  }, [hasModified, protocolo, searchParams, selectedAcao, validateProtocolo])

  const handleGetProtocolo = useCallback(async () => {

    const proCodigo = searchParams.get('pro_codigo');
    const ptlCodigo = searchParams.get('ptl_codigo') || "";

    if (ptlCodigo.length > 0) {

      const params = {
        proCodigo: proCodigo,
      }

      const token = searchParams.get('token')

      const result = await getProtocolo(params, token, ptlCodigo);

      if (selectedAcao === "copiar") {        
        const mappedData = {
          ...result.data,
          ptlNome: `${result.data.ptlNome} - ${translations["lbl.react.protocolo.copia"]}`
        }
        setProtocolo(mappedData);
        const fasesDMapped = result.data.fases.map((fase: ProtocoloFase) => fase.faseD)
        setFasesD(fasesDMapped)
      } else {
        setProtocolo(result.data);
      }

    }
    
  }, [searchParams, selectedAcao, translations]);

  const handleGetVacinas = useCallback(async () => {

    const proCodigo = searchParams.get('pro_codigo');

    const token = searchParams.get('token')

    const result = await getVacinas(proCodigo, token);

    const vacCombo = result.map((vacina: Vacina) => ({
      value: `${vacina.vacDescricao} (${vacina.vacMedida})`,
      valueKey: `${vacina.vacCodigo}`
    }))

    setVacinaCombo(vacCombo)
    
  }, [searchParams]);

  const handleAddItem = (faseIndex: number) => {
    if (protocolo) {
      const fasesEditadas = protocolo.fases.map((fase: ProtocoloFase, index: number) => {
        if (faseIndex === index) {
          fase.itens.push({
            itemDesc: '',
            itemQtd: 0,
            itemTipo: '',
            vacCodigo: ""
          })
          return {
            ...fase,
            itens: fase.itens
          }
        }
        return fase
      })

      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...fasesEditadas] })
      )

      setHasModified(true)        
    }
  }

  const handleAddFase = () => {
    if (protocolo) {
      protocolo.fases.push({
        faseD: Math.max(...(protocolo.fases.map((fase: ProtocoloFase) => fase.faseD))) + 1,
        faseNome: '',
        faseCodigo: Math.max(...(protocolo.fases.map((fase: ProtocoloFase) => fase.faseCodigo!))) + 1,
        itens: [{
          itemDesc: '',
          itemQtd: 0,
          itemTipo: '',
          vacCodigo: ""
        }],
      })

      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...prevState!.fases]
      }))
      setFasesD(protocolo.fases.map((fase: ProtocoloFase) => fase.faseD))
      setHasModified(true)   
    }
  }

  const handleDeleteFase = (faseIndex: number) => {
    if (protocolo) {
      const resultFases = protocolo.fases.filter((fase: ProtocoloFase, index: number) => faseIndex !== index)

      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...resultFases]
      }))
      setFasesD(protocolo.fases.map((fase: ProtocoloFase) => fase.faseD))
      setHasModified(true)   
    }
  }

  const handleDeleteItem = (faseIndex: number, itemIndex: number) => {
    if (protocolo) {
      const fasesEditadas = protocolo.fases.map((fase: ProtocoloFase, indexF: number) => {
        if (faseIndex === indexF) {
          const resultItens = fase.itens.filter((item: ProtocoloItem, indexI: number) => itemIndex !== indexI )
          return {
            ...fase,
            itens: resultItens
          }
        }
        return fase
      })

      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...fasesEditadas] })
      )
      
      setHasModified(true)   
    }
  }

  const handleApis = useCallback(async () => {
    setLoading(true);
    try {
      await handleGetVacinas();
      
      if (["editar", "copiar", "visualizar"].includes(selectedAcao)) {
        await handleGetProtocolo();
      } else {
        const novoProtocolo: Protocolo = {
          ptlAtivo: true,
          ptlData: new Date(),
          ptlFabricante: '',
          ptlNome: '',
          ptlObs: '',
          fases: [{
            faseD: 0,
            faseNome: '',
            faseCodigo: 1,
            itens: [{
              itemDesc: '',
              itemQtd: 0,
              itemTipo: '',
              vacCodigo: ""
            }],
          }],
        };
        setProtocolo(novoProtocolo);
      }
    } catch (error) {
      console.error("Erro ao realizar as requisições:", error);
    } finally {
      setLoading(false);
    }
  }, [handleGetProtocolo, handleGetVacinas, selectedAcao])

  useEffect(() => {
    handleApis()
  }, [handleApis]);

  const handleItemInputChange = (event: any, itemIndex: number, faseIndex: number) => {
    const {
      target: { value, name },
    } = event;
    console.log(value, name)
    if (protocolo) {
      const fasesEditadas = protocolo.fases.map((fase: ProtocoloFase, indexF: number) => {
        if (faseIndex === indexF) {
          return {
            ...fase,
            itens: fase.itens.map((item, indexT: number) => {
              if (itemIndex === indexT) {
                return {
                  ...item,
                  [name]: value
                }
              }
              return item
            })
          }
        }
        return fase
      })

      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...fasesEditadas] })
      )

      setHasModified(true)   
    }
  };

  const handleFaseInputChange = (event: any, faseIndex: number) => {
    const {
      target: { value, name },
    } = event;
    console.log(value, name)
    if (protocolo) {
      const fasesEditadas = protocolo.fases.map((fase: ProtocoloFase, index: number) => {
        if (index === faseIndex) {
          return {
            ...fase,
            faseD: value,
            itens: fase.itens
          }
        }
        return fase
      })

      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...fasesEditadas] })
      )

      setFasesD(protocolo.fases.map((fase: ProtocoloFase) => fase.faseD))

      setHasModified(true)   
    }
  };

  const handleProtocoloInputChange = (event: any) => {
    const {
      target: { value, name },
    } = event;

    setProtocolo(prevState => (
      { ...prevState!, ...{[name]: value} })
    )

    setHasModified(true)   
  };

  const handleProtocoloSwitchChange = () => {
    setProtocolo(prevState => (
      { ...prevState!, ptlAtivo: !prevState!.ptlAtivo })
    )

    setHasModified(true)   
  };

  const datePickerHandleChangeSingle = (date: Date) => {
    setProtocolo(prevState => (
      { ...prevState!, ptlData: date })
    )

    setHasModified(true)   
  };

  const datePickerHandleClear = () => {
    setProtocolo(prevState => (
      { ...prevState!, ptlData: "" }))
  };

  const sortFases = () => {
    if (protocolo) {
      setProtocolo(prevState => (
        { ...prevState!, fases: [ ...prevState!.fases.sort((a, b) => a.faseD - b.faseD) ] }))
    }
  }

  const handlePageTitle = () => {
    const acao = searchParams.get('acao') || "novo"
    switch (acao) {
      case "novo":
        return translations["lbl.react.protocolo.novo.protocolo"]
      case "editar":
        return translations["lbl.react.protocolo.editar.protocolo"]
      case "copiar":
        return translations["lbl.react.protocolo.novo.protocolo"]
      case "visualizar":
        return translations["lbl.react.protocolo.visualizar.protocolo"]
      default:
        return translations["lbl.react.protocolo.novo.protocolo"]
    }
  }

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title">
              { translations["lbl.react.protocolos"] }
            </span>
            { loading === false ?
              <Grid container rowSpacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Grid container padding="22px" pr={0} mb={1}>
                    <Grid item xs={6} mb={5}>
                      <span onClick={() => window.history.back()} className="page-bread pointer">
                        { translations["lbl.react.protocolo.iatf"] }
                      </span>
                      <span className="page-bread">
                        { ` > ${handlePageTitle()}` }
                      </span>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"} flexDirection={"row"}>
                      <Button onClick={() => window.history.back()} className="btn-secundario btn-voltar" variant="contained"><ArrowBackIcon /> { translations["lbl.react.protocolo.voltar.lista"] }</Button>
                    </Grid>
                    <Grid item xs={12} className="page-title">
                      <span>
                      { handlePageTitle() }
                      </span>
                    </Grid>
                  </Grid>
                  <Box sx={{ width: "100%", backgroundColor: "#f7f7f7", border: "1px solid #f7f7f7", borderRadius: "3px"} }>
                    <Grid container padding="22px">
                      <Grid item xs={12}>
                        <span>
                          { translations["lbl.react.dados.iniciais"] }
                        </span>
                      </Grid>
                      <Grid item container xs={12} mt={4}>
                        <Grid item xs={4}>
                          <span className="input-label">{ `* ${translations["lbl.react.nome.protocolo"]}` }</span>
                          <InputCustom
                            placeholder={''}
                            controlledValue={protocolo!.ptlNome}
                            name={'ptlNome'}
                            handleChange={handleProtocoloInputChange}
                            isValid={protocolo!.ptlNome !== "" || protocoloIsValid}
                            readOnly={selectedAcao === "visualizar" ? true : false}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} mt={1}>
                        <Grid item xs={4}>
                          <span className="input-label">{ `* ${translations["lbl.react.protocolo.data.criacao"]}` }</span>
                          <DataPickerCustom
                            onlyMonths={false}
                            dateFormat="dd/MM/yyyy"
                            handleChange={datePickerHandleChangeSingle}
                            handleClear={datePickerHandleClear}
                            handleClickOutside={() => {}}
                            startDate={protocolo!.ptlData ? new Date(protocolo!.ptlData) : null}
                            endDate={null}
                            selectRange={false}
                            placeholder=""
                            limitMaxDate={false}
                            isValid={protocolo!.ptlData !== "" || protocoloIsValid}
                            readOnly={selectedAcao === "visualizar" ? true : false}
                          />  
                        </Grid>
                      </Grid>
                      { /*<Grid item container xs={12} mt={1}>
                        <Grid item xs={4}>
                          <span className="input-label">{ `* ${translations["lbl.react.protocolo.fabricante"]}` }</span>
                          <InputCustom
                            placeholder={''}
                            controlledValue={protocolo!.ptlFabricante}
                            name={'ptlFabricante'}
                            handleChange={handleProtocoloInputChange}
                            readOnly={selectedAcao === "visualizar" ? true : false}
                          />
                        </Grid>
                      </Grid> */}
                      <Grid item xs={12} mt={1}>
                        <span className="input-label">{ translations["lbl.react.protocolo.observacao"] }</span>
                        <InputCustom
                          placeholder={''}
                          controlledValue={protocolo!.ptlObs}
                          name={'ptlObs'}
                          handleChange={handleProtocoloInputChange}
                          readOnly={selectedAcao === "visualizar" ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <Switch
                          className="switch-protocolo"
                          checked={protocolo!.ptlAtivo}
                          onChange={handleProtocoloSwitchChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          name='ptlAtivo'
                        />
                        <span className="input-label">{ translations["lbl.react.protocolo.ativar.desativar"] }</span>
                      </Grid>
                    </Grid>
                  </Box>
                  <AccordionCustom
                    title={translations["lbl.react.protocolo.fases"]}
                    subtitle=""
                    amountText=""
                    intervalTotal={0}
                    topButtons={
                      <Grid container columnGap={2} alignItems="center">
                        <span >{`${translations["lbl.react.fases.cadastradas"]} ${protocolo?.fases.length}`}</span>
                        { selectedAcao !== "visualizar" &&
                          <Button onClick={handleAddFase} startIcon={<img src={addCircleOutlineIcon} alt="novo cadastro icon" />} className="btn-primario" variant="contained" >{ translations["lbl.react.nova.fase"] }</Button>
                        }
                      </Grid>
                    }
                  >
                    <Grid>
                      <Grid px={2}>
                        {
                          protocolo?.fases.map((fase: ProtocoloFase, index: number) => (
                            <Fase
                              key={fase.faseCodigo}
                              fase={fase}
                              faseIndex={index}
                              itemInputChange={handleItemInputChange}
                              faseInputChange={handleFaseInputChange}
                              handleDeleteFase={handleDeleteFase}
                              handleDeleteItem={handleDeleteItem}
                              handleAddItem={handleAddItem}
                              handleSortFases={sortFases}
                              vacCombo={vacinaCombo}
                              fasesD={fasesD}
                            />
                          ))
                        }
                      </Grid>
                      { selectedAcao !== "visualizar" &&
                        <Grid item xs={12} px={2}>
                          <Button onClick={handleAddFase} startIcon={<img src={addCircleOutlineIcon} alt="novo cadastro icon" />} className="btn-primario max-width" variant="contained" >{ translations["lbl.react.nova.fase"] }</Button>
                        </Grid>
                      }
                      <Grid mt={2} mb={3} container justifyContent="end" alignItems="center" columnGap={2} px={2}>
                        <Grid>
                          <Button onClick={() => window.history.back()} className="btn-secundario" variant="contained" >{ translations["lbl.react.cancelar"] }</Button>
                        </Grid>
                        <Grid>
                          <Button onClick={() => handleSaveProtocolo()} className="btn-primario" variant="contained" >{ translations["lbl.react.salvar"] }</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionCustom>
                </Grid>
              </Grid>
              :
              <Loading setHeight="85vh"/>
            }
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default NovoProtocolo;