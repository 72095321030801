import React, { useEffect, useContext, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../../App';
import Item from './Item';
import addIcon from '../../assets/imgs/addIcon.svg'
import { ReactComponent as TrashIcon} from '../../assets/imgs/trashIcon.svg'
import InputNumberCustom from '../InputNumberCustom';

interface FaseProps {
  fase: ProtocoloFase
  faseIndex: number
  itemInputChange: any
  faseInputChange: any
  handleAddItem: any
  handleDeleteFase: any
  handleDeleteItem: any
  vacCombo: VacinaCombo[]
  handleSortFases: any
  fasesD: number[]
}

export default function ProtocoloFase(props: FaseProps) {
  const { fase: { faseD, itens, faseCodigo }, faseIndex, itemInputChange, faseInputChange, handleAddItem, vacCombo, handleDeleteFase, handleDeleteItem, handleSortFases, fasesD } = props
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const [ editingFase, setEditingFase ] = useState(false);
  const [ conflictingFase, setConflictingFase ] = useState(false);
  const [selectedAcao, setSelectedAcao] = useState<string>("");

  useEffect(() => {
    const languageParam = searchParams.get('language') || 'pt'
    setLanguage(languageParam)
  }, [searchParams, setLanguage]);

  useEffect(() => {
    const acao = searchParams.get('acao') || "novo"
    setSelectedAcao(acao)
  }, [searchParams, selectedAcao]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

      const fasesDFilter = fasesD.map((d: number, index: number) => {
        if (index !== faseIndex) return d
        return null
      })

      setEditingFase(true)
      faseInputChange(event, faseIndex)
      if (!fasesDFilter.includes(value)) {
        setConflictingFase(false)
      } else {
        setConflictingFase(true)
        console.log("Já possui")
      }    

  }

  const handleFaseFocusChange = (event: any) => {

    if (event.type === "blur") {
      handleSortFases()
      setEditingFase(false)
    } else if (event.key === "Enter") {
      handleSortFases()
      setTimeout(() => {
        document.getElementById(`fase-${faseCodigo}`)!.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }    

  }


  const handleFaseIsValid = () => {
    if (faseD === null) return false
    if (conflictingFase) return false
    if (faseD.toString() === "") return false
    if (faseD.toString() === "-") return false
    if (typeof faseD === "string") return false
    return true
  }

  return (
    <Grid id={`fase-${faseCodigo}`} item container xs={12} mt={2} boxShadow={editingFase ? "0px 0px 12px 2px rgba(11, 60, 86, 0.55)" : ""}>
      <Box sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px"} }>
        <Grid item container xs={12} px={1} justifyContent="space-between" alignItems="center" py={1}>
          <Grid className="protocolo-fase__header" item xs={4} >
            <span>
              {`Fase ${faseIndex + 1}`}
            </span>
          </Grid>
          <Grid item container justifyContent="center" xs={4} columnGap={2} alignItems="center">
            <Grid item className="fase-title">
              <span>
                D
              </span>
            </Grid>
            <Grid className="fase-input">
              <InputNumberCustom
                toolTipTitle={conflictingFase ? "Fase D não pode se repetir" : ""}
                allowDecimals={false}
                placeholder=""
                handleChange={(event: any) => handleChange(event)}
                handleBlurChange={(event: any) => handleFaseFocusChange(event)}
                controlledValue={faseD}
                name="faseD"
                isValid={handleFaseIsValid()}
                readOnly={selectedAcao === "visualizar" ? true : false}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="end" columnGap={2}>
            { selectedAcao !== "visualizar" &&
              <Button onClick={() => handleAddItem(faseIndex)} startIcon={<img src={addIcon} alt="novo cadastro icon" />} className="btn-secundario" variant="contained" >{ translations["lbl.react.protocolo.novo.item"] }</Button>
            }
            { selectedAcao !== "visualizar" &&
              <div className="trash-button" onClick={() => handleDeleteFase(faseIndex)} ><TrashIcon /></div>
            }
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", backgroundColor: "#ffffff", border: "1px solid #ffffff", borderRadius: "3px"} }>
        <Grid container item xs={12} px={3} py={2} mb={3}>
          <Grid container px={3} borderBottom={"1px solid #efefef;"} pb={2}>
            <Grid className="protocolo-item__title" item xs={3} pl={selectedAcao !== "visualizar" ? 4 : 0}>
              <span>{ `* ${translations["lbl.react.protocolo.tipo"]}` }</span>
            </Grid>
            <Grid className="protocolo-item__title" item xs={4}>
              <span>{ translations["lbl.react.protocolo.descricao"] }</span>
            </Grid>
            <Grid className="protocolo-item__title" item xs={4}>
              <span>{ `* ${translations["lbl.react.protocolo.produto"]}` }</span>
            </Grid>
            <Grid className="protocolo-item__title" item xs={1}>
              <span>{ `* ${translations["lbl.react.protocolo.quant"]}` }</span>
            </Grid>
          </Grid>
          <Grid container px={3} >
            {
              itens.map((item: ProtocoloItem, index: number) => (
                <Item
                  key={index}
                  item={item}
                  itemIndex={index}
                  faseIndex={faseIndex}
                  inputChange={itemInputChange}
                  vacCombo={vacCombo}
                  handleDeleteItem={handleDeleteItem}
                />
              ))
            }
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}