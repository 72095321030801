import { Input } from '@mui/material';

interface SelectProps {
  handleChange?: any;
  handleBlurChange?: any;
  placeholder: string;
  controlledValue: string | number;
  name: string;
  type?: string;
  isValid?: boolean;
  readOnly?: boolean;
}

export default function InputCustom(props: SelectProps) {
  const { placeholder, handleChange, controlledValue, name, type = "string", handleBlurChange, isValid = true, readOnly = false } = props;
  
  return (
    <Input
      readOnly={readOnly}
      type={type}
      disableUnderline
      fullWidth
      placeholder={placeholder}
      name={name}
      value={controlledValue}
      onChange={handleChange}
      onBlur={handleBlurChange}
      className={`input-custom ${isValid ? '' : 'invalid'}`}
      inputProps={{ 'aria-label': 'Without label' }}
      autoComplete="off"
    /> 
  );
}