import React, { useCallback, useEffect, useState, useContext } from 'react';
import Tabs from '../components/Tabs';
import Filter from '../components/Filter';
import { Grid, Button } from '@mui/material';
import fieldsTables from '../utils/fieldsTables';
import showColumns from '../utils/showColumns';
import { showFilters } from '../utils/configFilters';
import Table from '../components/Table';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { LanguageContext } from '../App';
import { deleteProtocolo, getProtocolos, updateProtocoloStatus } from '../apis/apiProtocolo';
import ballotIcon from '../assets/imgs/ballotIcon.svg'

function Protocolo() {
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [protocolos, setProtocolos] = useState<Protocolo[]>([])
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const [datePlaceholder, setDatePlaceholder] = useState<string>("");

  const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
    if (value === "listar_protocolos") setDatePlaceholder(translations["lbl.react.filtro.por.data.criacao"])
    else setDatePlaceholder(translations["lbl.react.data.nascimento"])
    setSelectedTab(value);
  };

  const handleGetProtocolos = useCallback(async (filters: FiltersType) => {
    setLoading(true)
    const proCodigo = searchParams.get('pro_codigo');

    const params = {
      proCodigo: proCodigo,
      dataIni: filters.startDate,
      dataFim: filters.endDate,
      lotCodigo: filters.lotCodigo,
      status: filters.genericStatus ? filters.genericStatus === "1" ? true : false : ""
    }

    const token = searchParams.get('token')

    const result = await getProtocolos(params, token);
    const mappedResult: Protocolo[] = result.data.map((protocolo: Protocolo) => ({
      ...protocolo,
      qtdFases: protocolo.fases.length,
      qtdDias: Math.abs(Math.min(...(protocolo.fases.map((fase: ProtocoloFase) => fase.faseD)))) + Math.max(...(protocolo.fases.map((fase: ProtocoloFase) => fase.faseD)))
    }))
    setProtocolos(mappedResult);

    setLoading(false)
    
  }, [searchParams]);

  const handleSearchButton = (filters: FiltersType) => {
    const mappedFilters = {
      ...filters,
      startDate: filters.startDate ? dayjs(filters.startDate).format("YYYY-MM-DD") : "",
      endDate: filters.endDate ? dayjs(filters.endDate).format("YYYY-MM-DD") : ""
    }
    handleGetProtocolos(mappedFilters)
  }

  const handleNovoProtocoloButton = () => {
    const proCodigo = searchParams.get('pro_codigo');
    const language = searchParams.get('language') || 'pt'
    const token = searchParams.get('token');
    window.location.href = `../cadastros/protocolo?pro_codigo=${proCodigo}&acao=novo&token=${token}&language=${language}`
  }

  const handleDeleteProtocolo = async (ptlCodigo: string) => {
    const proCodigo = searchParams.get('pro_codigo') || "";

    if (ptlCodigo && proCodigo.length > 0) {

      const token = searchParams.get('token')

      const result = await deleteProtocolo(token, proCodigo, ptlCodigo);

      if (result.status === 200) {
        handleGetProtocolos({
          startDate: '',
          endDate: '',
          espCodigo: '',
          racCodigo: '',
          sexo: '',
          lotCodigo: '',
          catCodigo: '',
          statusCria: ''
        })
      }
    }
  }

  const handleUpdateProtocoloStatus = async (protocolo: Protocolo) => {
    const proCodigo = searchParams.get('pro_codigo') || "";

    if (protocolo.ptlCodigo && proCodigo.length > 0) {

      const token = searchParams.get('token')

      const result = await updateProtocoloStatus({ status: !protocolo.ptlAtivo}, token, proCodigo, protocolo.ptlCodigo);

      if (result.status === 200) {
        handleGetProtocolos({
          startDate: '',
          endDate: '',
          espCodigo: '',
          racCodigo: '',
          sexo: '',
          lotCodigo: '',
          catCodigo: '',
          statusCria: ''
        })
      }
    }
  }

  useEffect(() => {

    if (selectedTab !== "") {
      handleGetProtocolos({
        startDate: '',
        endDate: '',
        espCodigo: '',
        racCodigo: '',
        sexo: '',
        lotCodigo: '',
        catCodigo: '',
        statusCria: ''
      });
    }

  }, [handleGetProtocolos, selectedTab]);

  useEffect(() => {

    const initialTab = searchParams.get('indicador') || "listar_protocolos"
    const languageParam = searchParams.get('language') || 'pt'
    setLanguage(languageParam)
    setSelectedTab(initialTab)

  }, [searchParams, setLanguage]);

  useEffect(() => {
    if (translations) setDatePlaceholder(translations["lbl.react.filtro.por.data.criacao"]);
  }, [translations]);

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title">
              { translations["lbl.react.protocolos"] }
            </span>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                { selectedTab &&
                  <Tabs handleChange={handleChangeTab} value={selectedTab} labels={[{ label: translations["lbl.react.protocolo.iatf.sincronizacao"], name: "listar_protocolos" }]}/>
                }
              </Grid>
              <Grid item xs={12}>
                <Filter
                  handleSearch={handleSearchButton}
                  datePlaceholder={datePlaceholder}
                  selectedTab={selectedTab}
                  selectRange={true}
                  showFilters={showFilters.listar_protocolos}
                  onlyMonths={false}
                  dateFormat="MM/yyyy"
                />
              </Grid>
              <Grid container item xs={12} mt={2} justifyContent="end">
                <Button onClick={() => handleNovoProtocoloButton()} startIcon={<img src={ballotIcon} alt="novo cadastro icon" />} className="btn-primario" variant="contained" >{ translations["lbl.react.novo.protocolo"] }</Button>
              </Grid>
              { loading === false ?
                <Grid item xs={12} pt={0}>
                  {selectedTab === "listar_protocolos" && (
                    1>0 ?
                      <div>
                        <Grid item xs={12}>
                          <Grid container item xs={12} pb={2} mb={3}>
                            <Table
                              columns={fieldsTables(translations, handleDeleteProtocolo, handleUpdateProtocoloStatus).protocolosCadastrados}
                              rows={protocolos}
                              idColumn='ptlCodigo'
                              sortColumn='ptlCodigo'
                              hasCheckbox={false}
                              tableTitle={translations["lbl.react.protocolos.iatf.cadastrados"]}
                              tableSubTitle={translations["lbl.react.protocolos.disponiveis.sistema"]}
                              name="protocolos"
                              printButton={false}
                              showColumns={showColumns.protocolosCadastrados}
                              handleCloseTable={() => {}}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                </Grid>
                :
                <Loading />
              }
            </Grid>
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default Protocolo;