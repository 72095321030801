const hiddenColumnsTables = {
  partos: {
    camDescricao: false
  },
  nascimentos: {
    camDescricao: false,
    catNome: false
  },
  concepcoes: {
    camDescricao: false,
    catNome: false
  },
  desmamesOcorridos: {
    camDescricao: false
  },
  protocolosCadastrados: {
  },
  desmamadosPorSemen: {
  }
}

export default hiddenColumnsTables
