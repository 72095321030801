import axios, { AxiosError } from 'axios';

async function getProtocolos(filters: ApiFiltersType, token: string | null) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/protocolo`,
      params: {
        ...filters,
      }
    });
    
    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

async function getProtocolo(filters: ApiFiltersType, token: string | null, ptlCodigo: string) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'get',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/protocolo/${ptlCodigo}`,
      params: {
        ...filters,
      }
    });
    
    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

async function postProtocolo(postBody: Protocolo, token: string | null, proCodigo: string) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'post',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/protocolo?proCodigo=${proCodigo}`,
      data: postBody
    });

    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

async function putProtocolo(postBody: Protocolo, token: string | null, proCodigo: string, ptlCodigo: string) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'put',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/protocolo/${ptlCodigo}?proCodigo=${proCodigo}`,
      data: postBody
    });

    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

async function deleteProtocolo(token: string | null, proCodigo: string, ptlCodigo: string) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'delete',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/protocolo/${ptlCodigo}?proCodigo=${proCodigo}`,
    });

    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

async function updateProtocoloStatus(postBody: { status: boolean }, token: string | null, proCodigo: string, ptlCodigo: number | string) {
  try {
    const response = await axios({
      headers: {
        Authorization: token,
      },
      method: 'put',
      url: `${process.env.REACT_APP_SERVICE_API}/rest/json/protocolo/status/${ptlCodigo}?proCodigo=${proCodigo}`,
      data: postBody
    });

    return { status: response.status, data: response.data }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return { status: axiosError.response.status, data: axiosError.message }
      }
      console.error(axiosError.message);
      return { status: axiosError.status ? axiosError.status : 500, data: axiosError.message }
    } else {
      return { status: 400, data: error }
    }
  }
}

export { getProtocolos, getProtocolo, postProtocolo, putProtocolo, deleteProtocolo, updateProtocoloStatus };
