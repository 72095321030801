import { GRID_CHECKBOX_SELECTION_COL_DEF, GridActionsCellItem, GridColumnHeaderParams, GridComparatorFn, GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';

const animalStatusEnum = (translations: any) => [
  { key: "L", value: translations["lbl.react.tabela.em.estoque"]},
  { key: "V", value: translations["lbl.react.tabela.vendidos"] },
  { key: "B", value: translations["lbl.react.tabela.baixas"] },
  { key: "T", value: translations["lbl.react.tabela.transferidos"] }
]

const animalTipoPrenhezEnum = (translations: any) => [
  { key: "P", value: translations["lbl.react.tabela.primeira.iatf"]},
  { key: "S", value: translations["lbl.react.tabela.segunda.iatf"] },
  { key: "T", value: translations["lbl.react.tabela.terceira.iatf"] },
  { key: "R", value: translations["lbl.react.tabela.repasse"] },
  { key: "I", value: translations["lbl.react.tabela.ia.conv"] },
  { key: "M", value: translations["lbl.react.tabela.monta"] }
]

const animalStatusPrenhezEnum = (translations: any) => [
  { key: 0, value: translations["lbl.react.tabela.nao.parida"] },
  { key: 1, value: translations["lbl.react.tabela.parida"] },
  { key: 2, value: translations["lbl.react.tabela.abortado.lactacao"] },
  { key: 3, value: translations["lbl.react.tabela.abortado"] },
  { key: 4, value: translations["lbl.react.tabela.monta.sem.prenhez"] }
]

const getUrlParams = () => {
  const paramSearch = new URLSearchParams(document.location.search);
  const token =   paramSearch.get("token");
  const proCodigo = paramSearch.get('pro_codigo');
  const language = paramSearch.get('language') || 'pt'
  return { token, proCodigo, language }
}

const dateComparator: GridComparatorFn<string> = (v1, v2) => {
  const date1Splitted = v1.split('/')
  const date2Splitted = v2.split('/')
  const date1 = date1Splitted[0] === '-' ? new Date() : new Date(parseInt(date1Splitted[2]), parseInt(date1Splitted[1]), parseInt(date1Splitted[0]))
  const date2 = date2Splitted[0] === '-' ? new Date() : new Date(parseInt(date2Splitted[2]), parseInt(date2Splitted[1]), parseInt(date2Splitted[0]))
  return date1.getTime() - date2.getTime()
}

const calcGanhoGmd = (pesoNascimento: number, pesoDesmame: number, diferencaDias: number) => {
  if (pesoNascimento !== 0 && pesoDesmame !== 0) {
    return {
      ganho: (pesoDesmame - pesoNascimento).toString().replace(".",","),
      gmd: ((pesoDesmame - pesoNascimento) / diferencaDias).toFixed(3).replace(".", ",")
    }
  } else {
    return {
      ganho: "-",
      gmd: "-"
    }
  }
}

const fieldsTables = (translations: any, handleDeleteRow?: any, handleUpdateRow?: any) => ({
  partos: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"]
    },
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 70,
      flex: 1,
      hideable: false
    },
    { field: 'idEletronico', headerName: translations["lbl.react.tabela.id.eletronico"], minWidth: 130 },
    { 
      field: 'catNome',
      headerName: translations["lbl.react.tabela.categoria"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria.catNome || ''}`,
    },
    { 
      field: 'racNome',
      headerName: translations["lbl.react.tabela.raca"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.raca.racNome || ''}`,
    },
    { field: 'idadeMeses', headerName: translations["lbl.react.tabela.idade.meses"], minWidth: 130, flex: 1 },
    { field: 'pesoAtual', headerName: translations["lbl.react.tabela.peso.atual.kg"], minWidth: 130, flex: 1 },
    { field: 'lotDescricao', headerName: translations["lbl.react.tabela.lote"], minWidth: 130, flex: 1 },
    { field: 'camDescricao', headerName: translations["lbl.react.tabela.localidade"], minWidth: 130, flex: 1 },
    {
      field: 'cobData',
      headerName: translations["lbl.react.tabela.ultima.cobertura"],
      description: translations["lbl.react.tabela.data.ultima.cobertura"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) =>
      new Date(new Date(params.row.cobData).setUTCHours(12))
    },
    { field: 'reprodutor', headerName: translations["lbl.react.tabela.reprodutor"], minWidth: 130, flex: 1 },
    {
      field: 'ultimoParto',
      headerName: translations["lbl.react.tabela.ultimo.parto"],
      description: translations["lbl.react.tabela.data.ultimo.parto"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.ultimoParto ? new Date(new Date(params.row.ultimoParto).setUTCHours(12)) : ""
    },
    {
      field: 'prevParto',
      headerName: translations["lbl.react.tabela.previsao.parto"],
      description: translations["lbl.react.tabela.data.previsao.parto"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) =>
      new Date(new Date(params.row.prevParto).setUTCHours(12))
    },
    { field: 'iepPrev', description: translations["lbl.react.tabela.intervalo.partos.previstos"], headerName: translations["lbl.react.tabela.iep.previsto.dias"], minWidth: 130, flex: 1 },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.visualizar"],
      description: translations["lbl.react.tabela.visualizar"],
      minWidth: 130,
      flex: 0.5,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label=""
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.id}&acao=opcoes`}
        />
      ]
    },
  ],
  nascimentos: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"],
      editable: true
    },
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 70,
      flex: 1,
      hideable: false,
      editable: true
    },
    { field: 'idEletronico', headerName: translations["lbl.react.tabela.id.eletronico"], minWidth: 130 },
    { 
      field: 'catNome',
      headerName: translations["lbl.react.tabela.categoria.atual"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria.catNome || ''}`,
        editable: true
    },
    { 
      field: 'racNome',
      headerName: translations["lbl.react.tabela.raca"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.raca.racNome || ''}`,
    },
    { 
      field: 'aniSexo',
      headerName: translations["lbl.react.tabela.sexo"],
      minWidth: 90,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.aniSexo === "M" ? translations["lbl.react.macho"] : params.row.aniSexo === "F" ? translations["lbl.react.femea"] : ""}`,
    },
    {
      field: 'dataNascimento',
      headerName: translations["lbl.react.tabela.data.nascimento"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(new Date(params.row.dataNascimento).setUTCHours(12))
      }
    },
    { 
      field: 'idadeMeses',
      headerName: translations["lbl.react.tabela.idade.meses"],
      minWidth: 130,
      flex: 1,
    },
    { 
      field: 'pesoNascimento',
      headerName: translations["lbl.react.tabela.peso.nasc.kg"],
      minWidth: 130,
      flex: 1,
    },
    { field: 'lotDescricao', headerName: translations["lbl.react.tabela.lote"], minWidth: 130, flex: 1 },
    { field: 'camDescricao', headerName: translations["lbl.react.tabela.localidade"], minWidth: 130, flex: 1 },
    { 
      field: 'aniSituacao',
      headerName: translations["lbl.react.tabela.status.cria"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.value ? animalStatusEnum(translations).find(status => status.key === params.row.aniSituacao)?.value : ''}`
    },
    { 
      field: 'aniPai',
      headerName: translations["lbl.react.tabela.id.visual.pai"],
      minWidth: 130,
      flex: 1,
      resizable: true
    },
    { 
      field: 'idVisualMae',
      headerName: translations["lbl.react.tabela.id.visual.mae"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.row.mae.idVisual || ''}`
    },
    { 
      field: 'eccMae',
      headerName: translations["lbl.react.tabela.ecc.mae.parto"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.mae.indiceCorp || ''}`,
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.visualizar"],
      description: translations["lbl.react.tabela.visualizar"],
      minWidth: 130,
      flex: 0.8,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label=""
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.id}&acao=opcoes`}
        />
      ]
    },
  ],
  concepcao: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"],
    },
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 130,
      flex: 1,
      hideable: false,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.animal.idVisual || ''}`,
    },
    {
      field: 'idEletronico',
      headerName: translations["lbl.react.tabela.id.eletronico"],
      minWidth: 130,
      flex: 0.8,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.animal.idEletronico || ''}`,
    },
    { 
      field: 'catNome',
      headerName: translations["lbl.react.tabela.categoria.atual"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria.catNome || ''}`,
    },
    { 
      field: 'racNome',
      headerName: translations["lbl.react.tabela.raca"],
      minWidth: 130,
      flex: 1.2,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.raca.racNome || ''}`,
    },
    { 
      field: 'idadeMeses',
      headerName: translations["lbl.react.tabela.idade.meses"],
      minWidth: 70,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
      `${dayjs(Date.now()).diff(dayjs(params.row.animal.dataNascimento), "months")}`
    },
    { 
      field: 'idadeAnoMeses',
      headerName: translations["lbl.react.tabela.idade.anos.meses"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const yearsComplete = dayjs(Date.now()).diff(dayjs(params.row.animal.dataNascimento), "years")
        const months = (dayjs(Date.now()).diff(dayjs(params.row.animal.dataNascimento), "months")) - (yearsComplete * 12)
        return `${yearsComplete}a ${months}m`
      }
    },
    {
      field: 'pesoAtual',
      headerName: translations["lbl.react.tabela.peso.atual.kg"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${(params.row.animal.peso).replace('.', ',') || ''}`,
    },
    {
      field: 'lote',
      headerName: translations["lbl.react.tabela.lote"],
      minWidth: 130,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.animal.lote || ''}`,
    },
    { 
      field: 'status',
      headerName: translations["lbl.react.filter.status"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.row.animal.status ? animalStatusEnum(translations).find(status => status.key === params.row.animal.status)?.value : ''}`
    },
    { 
      field: 'statusDescarte',
      headerName: translations["lbl.react.descarte"],
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className='columnHeaderTitle-custom' style={{width: '50px'}}>
            {translations["lbl.react.descarte"]}
          </span>
          <div style={{ display: 'flex'}}>
            <Tooltip title={translations["lbl.react.table.tooltip.descarte"]}>
                <InfoIcon className='info-icon blue' fontSize='small'/>
            </Tooltip>
          </div>
        </div>
      ),
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.animal.statusDescarte ? "Sim" : "Não",
    },
    {
      field: 'inseminada',
      headerName: translations["lbl.react.inseminada"],
      description: translations["lbl.react.inseminada"],
      headerClassName: 'col-header__inseminada col-header__full',
      cellClassName: 'col-body__inseminada col-header__full',
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobData && params.row.cobData.length > 0 ? "Sim" : "Não",
    },
    {
      field: 'cobData',
      headerName: translations["lbl.react.tabela.data.iatf1"],
      description: translations["lbl.react.tabela.data.iatf1"],
      headerClassName: 'col-header__1atf col-header__start',
      cellClassName: 'col-body__1atf col-body__start',
      minWidth: 130,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobData ? `${new Date(new Date(params.row.cobData).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    { 
      field: 'semenIatf1',
      headerName: translations["lbl.react.tabela.semen.iatf1"],
      minWidth: 130,
      flex: 1.5,
      headerClassName: 'col-header__1atf col-header__end',
      cellClassName: 'col-body__1atf col-body__end',
    },
    {
      field: 'cobDataToqueN2',
      headerName: translations["lbl.react.tabela.data.iatf2"],
      description: translations["lbl.react.tabela.data.iatf2"],
      headerClassName: 'col-header__1atf col-header__start',
      cellClassName: 'col-body__1atf col-body__start',
      minWidth: 130,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobDataToqueN2 ? `${new Date(new Date(params.row.cobDataToqueN2).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    { 
      field: 'semenIatf2',
      headerName: translations["lbl.react.tabela.semen.iatf2"],
      minWidth: 130,
      flex: 1.5,
      headerClassName: 'col-header__1atf col-header__end',
      cellClassName: 'col-body__1atf col-body__end',
    },
    {
      field: 'cobDataToqueN3',
      headerName: translations["lbl.react.tabela.data.iatf3"],
      description: translations["lbl.react.tabela.data.iatf3"],
      headerClassName: 'col-header__1atf col-header__start',
      cellClassName: 'col-body__1atf col-body__start',
      minWidth: 130,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobDataToqueN3 ? `${new Date(new Date(params.row.cobDataToqueN3).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    { 
      field: 'semenIatf3',
      headerName: translations["lbl.react.tabela.semen.iatf3"],
      minWidth: 130,
      flex: 1.5,
      headerClassName: 'col-header__1atf col-header__end',
      cellClassName: 'col-body__1atf col-body__end',
    },
    {
      field: 'cobDataRt',
      headerName: translations["lbl.react.tabela.data.repasse"],
      description: translations["lbl.react.tabela.data.repasse"],
      headerClassName: 'col-header__1atf col-header__full',
      cellClassName: 'col-body__1atf col-body__start col-body__end',
      minWidth: 130,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobDataRt ? `${new Date(new Date(params.row.cobDataRt).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    {
      field: 'cobDataToque',
      headerName: translations["lbl.react.tabela.confirmacao.prenhez"],
      description: translations["lbl.react.tabela.confirmacao.prenhez"],
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className='columnHeaderTitle-custom' style={{width: '72px'}}>
            {translations["lbl.react.tabela.confirmacao.prenhez"]}
          </span>
          <div style={{ display: 'flex'}}>
            <Tooltip
            title={translations["lbl.react.tooltip.data.confirmacao.prenhez"]}
            >
                <InfoIcon className='info-icon blue' fontSize='small'/>

            </Tooltip>
          </div>
        </div>
      ),
      minWidth: 150,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobDataToque && params.row.cobIndToque === 1 ? `${new Date(new Date(params.row.cobDataToque).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    { 
      field: 'prenhez',
      headerName: translations["lbl.react.tabela.tipo prenhez"],
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <span className='columnHeaderTitle-custom' style={{width: '73px'}}>
          {translations["lbl.react.tabela.tipo prenhez"]}
          </span>
          <div style={{ display: 'flex'}}>
            <Tooltip
            title={translations["lbl.react.tooltip.retorna.cobertura.prenha"]}
            >
                <InfoIcon className='info-icon blue' fontSize='small'/>

            </Tooltip>
          </div>
        </div>
      ),
      minWidth: 130,
      flex: 1.3,
      valueGetter: (params: GridValueGetterParams) => {
        const iatf = params.row.cobIndToque === 1
          ? params.row.cobIndRt && (new Date(params.row.cobDataToque) > new Date(params.row.cobDataRt)) ? translations["lbl.react.tabela.repasse"]
          : params.row.cobIndN3 ? translations["lbl.react.tabela.terceira.iatf"]
          : params.row.cobIndN2 ? translations["lbl.react.tabela.segunda.iatf"]
          : translations["lbl.react.tabela.primeira.iatf"]
          : "-"

        return iatf
      }
    },
    {
      field: 'semen',
      headerName: translations["lbl.react.tabela.semen"],
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span className='columnHeaderTitle-custom' style={{width: '46px'}}>
            {translations["lbl.react.tabela.semen"]}
          </span>
          <div style={{ display: 'flex'}}>
            <Tooltip
             title={translations["lbl.react.tooltip.retorna.semen.prenhez"]}
            >
              <InfoIcon className='info-icon blue' fontSize='small'/>
            </Tooltip>
          </div>
        </div>
      ),
      description: translations["lbl.react.tabela.semen"],
      minWidth: 130,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) => {
        const semen = params.row.cobIndToque === 1
          ? params.row.cobIndRt && (new Date(params.row.cobDataToque) > new Date(params.row.cobDataRt)) ? "-"
          : params.row.cobIndN3 ? params.row.semenIatf3
          : params.row.cobIndN2 ? params.row.semenIatf2
          : params.row.semenIatf1
          : "-"

        return semen !== "" ? semen : "-"
      }
    },
    {
      field: 'diasGestacao',
      headerName: translations["lbl.react.dias.de.gestacao"],
      description: translations["lbl.react.tabela.data.repasse"],
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        let value = "";

        if (params.row.cobDataToque && params.row.cobIndToque === 1) {
          if (params.row.cobIndStatus === 0) {
            value = params.row.cobIndRt && (new Date(params.row.cobDataToque) > new Date(params.row.cobDataRt)) ? (dayjs().diff(params.row.cobDataRt, 'days')).toString()
            : params.row.cobIndN3 ? (dayjs().diff(params.row.cobDataToqueN3, 'days')).toString()
            : params.row.cobIndN2 ? (dayjs().diff(params.row.cobDataToqueN2, 'days')).toString()
            : params.row.cobData ? (dayjs().diff(params.row.cobData, 'days')).toString()
            : "-"
          } else if (params.row.cobIndStatus === 4) {
            value = translations["lbl.react.monta.s.prenhez"]
          } else if (params.row.cobIndStatus === 3 || params.row.cobIndStatus === 2) {
            value = translations["lbl.react.aborto"]
          } else {
            value = translations["lbl.react.parida"]
          }
        }

        return value !== "" ? value : "-";
      }
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.visualizar"],
      description: translations["lbl.react.tabela.visualizar"],
      minWidth: 130,
      flex: 0.8,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label=""
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.row.animal.aniCodigo}&acao=opcoes`}
        />
      ]
    },
  ],
  descartes: [
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 70,
      flex: 1,
      hideable: false,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.idVisual || ''}`,
    },
    {
      field: 'lote',
      headerName: translations["lbl.react.tabela.lote"],
      minWidth: 130,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.lote || ''}`,
    },
    {
      field: 'localidade',
      headerName: translations["lbl.react.tabela.localidade"],
      minWidth: 130,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.localidade || ''}`,
    },
    { 
      field: 'categoria',
      headerName: translations["lbl.react.tabela.categoria"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria || ''}`,
    },
    {
      field: 'pesoAtual',
      headerName: translations["lbl.react.tabela.peso.atual.kg"],
      minWidth: 70,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${(params.row.pesoAtual).replace('.', ',') || ''}`,
    },
    {
      field: 'desDataIni',
      headerName: translations["lbl.react.tabela.data.entrada"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.desDataIni ? `${new Date(new Date(params.row.desDataIni).setUTCHours(12)).toLocaleDateString()}` : "-",
    },
    {
      field: 'desDataFim',
      headerName: translations["lbl.react.tabela.data.saida"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.desDataFim ? `${new Date(new Date(params.row.desDataFim).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.acoes"],
      description: translations["lbl.react.tabela.acoes"],
      minWidth: 100,
      flex: 0.8,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label={translations["lbl.react.tabela.visualizar"]}
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.row.aniCodigo}&acao=opcoes`}
        />,
        <GridActionsCellItem
          label={translations["lbl.react.tabela.remover"]}
          icon={<DeleteIcon />}
          onClick={() => handleDeleteRow(params.row.aniCodigo)}
        />
      ]
    },
  ],
  prenhas: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"],
    },
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 70,
      flex: 1,
      hideable: false,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.animal.idVisual || ''}`,
    },
    {
      field: 'idEletronico',
      headerName: translations["lbl.react.tabela.id.eletronico"],
      minWidth: 130,
      flex: 0.8,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.animal.idEletronico || ''}`,
    },
    { 
      field: 'catNome',
      headerName: translations["lbl.react.tabela.categoria.atual"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria.catNome || ''}`,
    },
    { 
      field: 'racNome',
      headerName: translations["lbl.react.tabela.raca"],
      minWidth: 130,
      flex: 1.2,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.raca.racNome || ''}`,
    },
    { 
      field: 'idadeMeses',
      headerName: translations["lbl.react.tabela.idade.meses"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
      `${dayjs(Date.now()).diff(dayjs(params.row.animal.dataNascimento), "months")}`
    },
    { 
      field: 'idadeAnoMeses',
      headerName: translations["lbl.react.tabela.idade.anos.meses"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        const yearsComplete = dayjs(Date.now()).diff(dayjs(params.row.animal.dataNascimento), "years")
        const months = (dayjs(Date.now()).diff(dayjs(params.row.animal.dataNascimento), "months")) - (yearsComplete * 12)
        return `${yearsComplete}a ${months}m`
      }
    },
    {
      field: 'pesoAtual',
      headerName: translations["lbl.react.tabela.peso.atual.kg"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${(params.row.animal.peso).replace('.', ',') || ''}`,
    },
    {
      field: 'lote',
      headerName: translations["lbl.react.tabela.lote"],
      minWidth: 130,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) =>
      `${params.row.animal.lote || ''}`,
    },
    {
      field: 'cobPrenhezData',
      headerName: translations["lbl.react.tabela.data prenhez"],
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <span className='columnHeaderTitle-custom' style={{width: '72px'}}>
          {translations["lbl.react.tabela.data prenhez"]}
          </span>
          <div style={{ display: 'flex'}}>
            <Tooltip
            title={translations["lbl.react.tabela.tooltip.retorna.data.prenhez"]}
            >
                <InfoIcon className='info-icon blue' fontSize='small'/>

            </Tooltip>
          </div>
        </div>
      ),
      minWidth: 150,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobPrenhezData ? `${new Date(new Date(params.row.cobPrenhezData).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    { 
      field: 'cobPrenhezTipo',
      headerName: translations["lbl.react.tabela.tipo prenhez"],
      renderHeader: (params: GridColumnHeaderParams) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <span className='columnHeaderTitle-custom' style={{width: '60px'}}>
          {translations["lbl.react.tabela.tipo prenhez"]}
          </span>
          <div style={{ display: 'flex'}}>
            <Tooltip
            title={translations["lbl.react.tooltip.retorna.cobertura.prenha.curto"]}
            >
                <InfoIcon className='info-icon blue' fontSize='small'/>

            </Tooltip>
          </div>
        </div>
      ),
      minWidth: 140,
      flex: 1.5,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.value ? animalTipoPrenhezEnum(translations).find(status => status.key === params.row.cobPrenhezTipo)?.value : ''}`
    },
    {
      field: 'cobPrenhezPrevisao',
      headerName: translations["lbl.react.tabela.previsao.parto"],
      minWidth: 130,
      flex: 1.5,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobPrenhezPrevisao ? `${new Date(new Date(params.row.cobPrenhezPrevisao).setUTCHours(12)).toLocaleDateString()}` : "-"
    },
    { 
      field: 'cobPrenhezDias',
      headerName: translations["lbl.react.tabela.dias.gestacao"],
      minWidth: 130,
      flex: 1.3,
      valueGetter: (params: GridValueGetterParams) =>
      params.row.cobIndStatus > 0 ? `${animalStatusPrenhezEnum(translations).find(status => status.key === params.row.cobIndStatus)?.value}` : params.row.cobPrenhezDias
    },
    { 
      field: 'status',
      headerName: translations["lbl.react.filter.status"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.row.animal.status ? animalStatusEnum(translations).find(status => status.key === params.row.animal.status)?.value : ''}`
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.visualizar"],
      description: translations["lbl.react.tabela.visualizar"],
      minWidth: 130,
      flex: 0.8,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label=""
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.row.animal.aniCodigo}&acao=opcoes`}
        />
      ]
    },
  ],
  previsaoDesmame: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"]
    },
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 70,
      flex: 0.8,
      hideable: false
    },
    { field: 'idEletronico', headerName: translations["lbl.react.tabela.id.eletronico"], minWidth: 130 },
    { 
      field: 'categoria',
      headerName: translations["lbl.react.tabela.categoria"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria || ''}`,
    },
    { 
      field: 'raca',
      headerName: translations["lbl.react.tabela.raca"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.raca || ''}`,
    },
    { 
      field: 'sexo',
      headerName: translations["lbl.react.tabela.sexo"],
      minWidth: 90,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.sexo === "M" ? translations["lbl.react.macho"] : params.row.sexo === "F" ? translations["lbl.react.femea"] : ""}`,
    },
    {
      field: 'dataNascimento',
      headerName: translations["lbl.react.tabela.data.nascimento"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(new Date(params.row.dataNascimento).setUTCHours(12))
      }
    },
    { 
      field: 'idadeMeses',
      headerName: translations["lbl.react.tabela.idade.meses"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
      `${dayjs(Date.now()).diff(dayjs(params.row.dataNascimento), "months")}`
    },
    { field: 'peso', headerName: translations["lbl.react.tabela.peso.atual.kg"], minWidth: 130, flex: 1 },
    { field: 'lote', headerName: translations["lbl.react.tabela.lote"], minWidth: 130, flex: 1 },
    { field: 'aniNumeroPai', headerName: translations["lbl.react.tabela.id.visual.pai"], minWidth: 130, flex: 1 },
    { field: 'aniNumeroMae', headerName: translations["lbl.react.tabela.id.visual.mae"], minWidth: 130, flex: 1 },
    {
      field: 'dataPrevisao',
      headerName: translations["lbl.react.tabela.previsao.desmame"],
      description: translations["lbl.react.tabela.data.previsao.desmame"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) =>
      new Date(new Date(params.row.dataPrevisao).setUTCHours(12))
    },
    { 
      field: 'status',
      headerName: translations["lbl.react.filter.status"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.value ? animalStatusEnum(translations).find(status => status.key === params.row.status)?.value : ''}`
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.visualizar"],
      description: translations["lbl.react.tabela.visualizar"],
      minWidth: 130,
      flex: 0.5,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label=""
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.id}&acao=opcoes`}
        />
      ]
    },
  ],
  desmamesOcorridos: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"]
    },
    {
      field: 'idVisual',
      headerName: translations["lbl.react.tabela.id.visual"],
      minWidth: 70,
      flex: 0.8,
      hideable: false
    },
    { field: 'idEletronico', headerName: translations["lbl.react.tabela.id.eletronico"], minWidth: 130 },
    { 
      field: 'categoria',
      headerName: translations["lbl.react.tabela.categoria"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.categoria.catNome || ''}`,
    },
    { 
      field: 'raca',
      headerName: translations["lbl.react.tabela.raca"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.raca.racNome || ''}`,
    },
    { 
      field: 'sexo',
      headerName: translations["lbl.react.tabela.sexo"],
      minWidth: 90,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.aniSexo === "M" ? translations["lbl.react.macho"] : params.row.aniSexo === "F" ? translations["lbl.react.femea"] : ""}`,
    },
    {
      field: 'dataNascimento',
      headerName: translations["lbl.react.tabela.data.nascimento"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(new Date(params.row.dataNascimento).setUTCHours(12))
      }
    },
    {
      field: 'pesoNascimento',
      headerName: translations["lbl.react.tabela.peso.nasc.kg"], 
      minWidth: 130, 
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${(params.row.pesoNascimento.toString().includes('.') ? params.row.pesoNascimento.toFixed(2).replace('.', ',') : params.row.pesoNascimento)}`
    },
    {
      field: 'dataDesmame',
      headerName: translations["lbl.react.data.de.desmame"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(new Date(params.row.dataDesmame).setUTCHours(12))
      }
    },
    { field: 'idadeDesmameMeses', headerName: translations["lbl.react.idade.desmame.meses"], minWidth: 130, flex: 1 },
    {
      field: 'pesoDesmameKg', 
      headerName: translations["lbl.react.peso.desmame.kg"], 
      minWidth: 130, 
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${(params.row.pesoDesmameKg.toString().includes('.') ? params.row.pesoDesmameKg.toFixed(2).replace('.', ',') : params.row.pesoDesmameKg) || ''}`
    },
    { 
      field: 'pesoDesmameArroba', 
      headerName: translations["lbl.react.peso.desmame.arroba"], 
      minWidth: 130, 
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      `${(params.row.pesoDesmameArroba.toString().includes('.') ? params.row.pesoDesmameArroba.toFixed(2).replace('.', ',') : params.row.pesoDesmameArroba) || ''}`
    },
    {
      field: 'diasEntrePesagens',
      headerName: translations["lbl.react.tabela.dias.entre.pesagens"],
      description: translations["lbl.react.tabela.dias.entre.pesagens"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
      (new Date(params.row.dataDesmame).setUTCHours(12) - new Date(params.row.dataNascimento).setUTCHours(12)) / 86400000
    },
    {
      field: 'ganho',
      headerName: translations["lbl.react.tabela.ganho.kg"],
      description: translations["lbl.react.tabela.ganho.kg"],
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        calcGanhoGmd(params.row.pesoNascimento, params.row.pesoDesmameKg, 0).ganho
    },
    {
      field: 'gmd',
      headerName: translations["lbl.react.tabela.gmd"],
      description: translations["lbl.react.tabela.gmd"],
      minWidth: 70,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        calcGanhoGmd(params.row.pesoNascimento, params.row.pesoDesmameKg, (new Date(params.row.dataDesmame).setUTCHours(12) - new Date(params.row.dataNascimento).setUTCHours(12)) / 86400000).gmd
    },
    { field: 'lote', headerName: translations["lbl.react.tabela.lote"], minWidth: 130, flex: 1 },
    { field: 'aniPai', headerName: translations["lbl.react.tabela.id.visual.pai"], minWidth: 130, flex: 1 },
    { field: 'aniMae', headerName: translations["lbl.react.tabela.id.visual.mae"], minWidth: 130, flex: 1 },
    {
      field: 'dataPrevisao',
      headerName: translations["lbl.react.tabela.previsao.desmame"],
      description: translations["lbl.react.tabela.data.previsao.desmame"],
      minWidth: 130,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) =>
      new Date(new Date(params.row.previsaoDesmame).setUTCHours(12))
    },
    { 
      field: 'aniStatus',
      headerName: translations["lbl.react.filter.status"],
      minWidth: 130,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => 
        `${params.value ? animalStatusEnum(translations).find(status => status.key === params.row.aniStatus)?.value : ''}`
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.visualizar"],
      description: translations["lbl.react.tabela.visualizar"],
      minWidth: 130,
      flex: 0.5,
      type: 'actions',
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label=""
          icon={<VisibilityIcon />}
          onClick={() => window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/animal?key=${params.id}&acao=opcoes`}
        />
      ]
    },
  ],
  protocolosCadastrados: [
    {
      field: 'ptlNome',
      headerName: translations["lbl.react.tabela.protocolo"],
      minWidth: 150,
      flex: 2,
      hideable: false
    },
    {
      field: 'ptlData',
      headerName: translations["lbl.react.tabela.data.criacao"],
      minWidth: 100,
      flex: 1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(new Date(params.row.ptlData).setUTCHours(12))
      }
    },
    //{ field: 'ptlFabricante', headerName: translations["lbl.react.tabela.fabricante"], minWidth: 150, flex: 2 },
    { field: 'qtdFases', headerName: translations["lbl.react.tabela.n.de.fases"], minWidth: 85, flex: 0.6 },
    { field: 'qtdDias', headerName: translations["lbl.react.tabela.dias"], minWidth: 85, flex: 0.6 },
    { field: 'numAnimais', headerName: translations["lbl.react.tabela.animais.em.adocao"], minWidth: 100, flex: 0.8 },
    { field: 'numLotes', headerName: translations["lbl.react.tabela.lotes.atuais"], minWidth: 100, flex: 0.8 },
    { field: 'ptlObs', headerName: translations["lbl.react.tabela.observacao"], minWidth: 130, flex: 1.5 },
    { 
      field: 'ptlAtivo',
      headerName: translations["lbl.react.tabela.status"],
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <span className={`badge-status ${params.row.ptlAtivo ? "active" : "inactive"}`}>
          { params.row.ptlAtivo ? translations["lbl.react.protocolo.ativo"] : translations["lbl.react.protocolo.inativo"]}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: translations["lbl.react.tabela.acoes"],
      description: translations["lbl.react.tabela.acoes"],
      minWidth: 30,
      type: 'actions',
      flex: 0.5,
      hideable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
        label={params.row.ptlAtivo ? translations["lbl.react.protocolo.desativar"] : translations["lbl.react.protocolo.ativar"]}
        onClick={() => handleUpdateRow(params.row)}
        showInMenu
      />,
        <GridActionsCellItem
          label={translations["lbl.react.table.menu.visualizar"]}
          onClick={() => window.location.href = `../cadastros/protocolo?pro_codigo=${getUrlParams().proCodigo}&acao=visualizar&ptl_codigo=${params.row.ptlCodigo}&token=${getUrlParams().token}&language=${getUrlParams().language}`}
          showInMenu
        />,
        <GridActionsCellItem
        label={translations["lbl.react.table.menu.editar"]}
        onClick={() => window.location.href = `../cadastros/protocolo?pro_codigo=${getUrlParams().proCodigo}&acao=editar&ptl_codigo=${params.row.ptlCodigo}&token=${getUrlParams().token}&language=${getUrlParams().language}`}
        showInMenu
        />,
        <GridActionsCellItem
          label={translations["lbl.react.table.menu.criar.copia"]}
          onClick={() => window.location.href = `../cadastros/protocolo?pro_codigo=${getUrlParams().proCodigo}&acao=copiar&ptl_codigo=${params.row.ptlCodigo}&token=${getUrlParams().token}&language=${getUrlParams().language}`}
          showInMenu
        />,
        <GridActionsCellItem
          label={translations["lbl.react.table.menu.excluir"]}
          onClick={() => handleDeleteRow(params.row.ptlCodigo)}
          showInMenu
        />
      ]
    },
  ],
  desmamadosPorSemen: [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: translations["lbl.react.tabela.coluna.selecao"]
    },
    {
      field: 'semenName',
      headerName: translations["lbl.react.semens"],
      minWidth: 200,
      flex: 0.8,
      hideable: false
    },
    { 
      field: 'maleQuantity', 
      headerName: translations["lbl.react.quantidade.de.machos"],
      headerClassName: 'col-header__male col-header__start',
      cellClassName: 'col-body__male col-header__start',
      minWidth: 110
    },
    { 
      field: 'maleWeightKg', 
      headerName: translations["lbl.react.peso.medio.kg"],
      headerClassName: 'col-header__male',
      cellClassName: 'col-body__male', 
      minWidth: 110
    },
    { 
      field: 'maleWeightArroba', 
      headerName: translations["lbl.react.peso.medio.arroba"],
      headerClassName: 'col-header__male',
      cellClassName: 'col-body__male', 
      minWidth: 110
    },
    { 
      field: 'maleAgeInMonths', 
      headerName: translations["lbl.react.idade.media.meses"],
      headerClassName: 'col-header__male col-header__end',
      cellClassName: 'col-body__male col-header__end', 
      minWidth: 110
    },
    { 
      field: 'femaleQuantity', 
      headerName: translations["lbl.react.quantidade.de.femeas"],
      headerClassName: 'col-header__female col-header__start',
      cellClassName: 'col-body__female col-header__start',
      minWidth: 110
    },
    { 
      field: 'femaleWeightKg', 
      headerName: translations["lbl.react.peso.medio.kg"],
      headerClassName: 'col-header__female',
      cellClassName: 'col-body__female', 
      minWidth: 110
    },
    { 
      field: 'femaleWeightArroba', 
      headerName: translations["lbl.react.peso.medio.arroba"],
      headerClassName: 'col-header__female',
      cellClassName: 'col-body__female', 
      minWidth: 110
    },
    { 
      field: 'femaleAgeInMonths', 
      headerName: translations["lbl.react.idade.media.meses"],
      headerClassName: 'col-header__female col-header__end',
      cellClassName: 'col-body__female col-header__end', 
      minWidth: 110
    },
    { 
      field: 'totalQuantity', 
      headerName: translations["lbl.react.quantidade.total.de.animais"],
      minWidth: 110
    },
    { 
      field: 'totalWeightKg', 
      headerName: translations["lbl.react.peso.medio.kg"],
      minWidth: 110
    },
    { 
      field: 'totalWeightArroba', 
      headerName: translations["lbl.react.peso.medio.arroba"],
      minWidth: 110
    },
    { 
      field: 'totalAgeInMonths', 
      headerName: translations["lbl.react.idade.media.meses"],
      minWidth: 110
    }
  ]
})

export default fieldsTables
