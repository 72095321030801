import React, { useCallback, useEffect, useState, useContext, useRef, useMemo } from 'react';
import Tabs from '../components/Tabs';
import Filter from '../components/Filter';
import { getReproducoes } from '../apis/apiReproducoes';
import { getPropriedades } from '../apis/apiPropriedades';
import { getFemeasAptas } from '../apis/apiAnimais';
import { Box, Checkbox, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import fieldsTables from '../utils/fieldsTables';
import showColumns from '../utils/showColumns';
import { showFilters } from '../utils/configFilters';
import Table from '../components/Table';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { LanguageContext } from '../App';
import {
  separateByIatf1,
  separateByIatf2,
  separateByIatf3,
  separateByIatfTotal,
  separateByIatfTotalRepasse,
  separateByPrenhez,
  separateConceptionsBySemen,
  separateIatfBySemen,
  separateIatfsByPhaseAndSemen,
} from '../utils/separateData';
import BarChart from '../components/charts/BarChart';
import BarChartVertical from '../components/charts/BarChartVertical';
import ConceptionSummary from '../components/summaries/ConceptionSummary';
import IatfSummary from '../components/summaries/IatfSummary';
import SettingsIcon from '@mui/icons-material/Settings';
import ConceptsModal from '../components/modals/ConceptsModal';
import AccordionCustom from '../components/ArcodionCustom';
import GenericTablePagination from '../components/summaries/GenericTablePagination';
import PageForPrint from '../components/PageForPrint';
import Printer from '../components/Printer';
//import AbaPrenhas from '../components/subpages/AbaPrenhas';
import { getPrenhas } from '../apis/apiPrenhas';
import Badge from '../components/Badge';

type SemenSummaryData = {
  tableHeads: (string | number)[];
  tableUpperHeads?: (string | number)[];
  tableRows: Array<Array<string | number>>;
  tableHeadsTooltips?: {[key: number]: string};
  tableTotalizer?: Array<Array<string | number>>;
  tableTotalizerTooltips?: {[key: number]: string};
};

type FiltersPrintRefType = {
  espCodigo: string;
  racCodigo: string;
  catCodigo: string;
  lotCodigo: string;
};

type FarmsType = {
  proCodigo: number;
  proDescricao: string;
}

type SortOptionsType = {
  reference: string;
  order: string;
}

function IndicadoresPrenhez() {
  const [loading, setLoading] = useState<boolean>(true)
  const [barChartDataByIatf, setBarChartDataByIatf] = useState<BarChartData[]>([])
  const [barChartDataBySemen, setBarChartDataBySemen] = useState<BarChartData[]>([])
  const [barChartDataBySemenTotal, setBarChartDataBySemenTotal] = useState<BarChartData[]>([])
  const [barChartDataBySemenIatf1, setBarChartDataBySemenIatf1] = useState<BarChartVerticalData[]>([])
  const [barChartDataBySemenIatf2, setBarChartDataBySemenIatf2] = useState<BarChartVerticalData[]>([])
  const [barChartDataBySemenIatf3, setBarChartDataBySemenIatf3] = useState<BarChartVerticalData[]>([])
  const [barChartDataByConceptions, setBarChartDataByConceptions] = useState<BarChartData[]>([])
  const [selectedMonths, setSelectedMonths] = useState<string[]>([])
  const [selectedAnimalsByConceptions, setSelectedAnimalsByConceptions] = useState<Reproduction[]>([])
  const [sumaryIatfBySemenData, setSumaryIatfBySemenData] = useState<SemenSummaryData>({tableHeads: [], tableRows: [[]], tableHeadsTooltips: {}});
  const [sumaryIatfXSemenData, setSumaryIatfXSemenData] = useState<SemenSummaryData>({tableHeads: [], tableUpperHeads: [], tableRows: [[]], tableHeadsTooltips: {}, tableTotalizer: [[]], tableTotalizerTooltips: {}});
  const [sumaryIatfTotalBySemenData, setSumaryIatfTotalBySemenData] = useState<SemenSummaryData>({tableHeads: [], tableRows: [[]]});
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedConceptionSortConfig, setSelectedConceptionSortConfig] = useState<SortOptionsType>({reference: 'alphabetical', order: 'asc'});
  const [selectedShareSortConfig, setSelectedShareSortConfig] = useState<SortOptionsType>({reference: 'alphabetical', order: 'asc'});
  const [reproductions, setReproductions] = useState<Reproduction[]>([])
  const [considerarDescartados, setConsiderarDescartados] = useState<boolean>(false);
  const [reproductionsConceptions, setReproductionsConceptions] = useState<Reproduction[]>([])
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  const [datePlaceholder, setDatePlaceholder] = useState<string>("Filtro por data de cobertura");
  const [daysInterval, setDaysInterval] = useState<number>(365);
  const [modalConceptsOpen, setModalConceptsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorElConceptionSortMenu, setAnchorElConceptionSortMenu] = useState<null | HTMLElement>(null);
  const openConceptionSortMenu = Boolean(anchorElConceptionSortMenu);
  const [anchorElShareSortMenu, setAnchorElShareSortMenu] = useState<null | HTMLElement>(null);
  const openShareSortMenu = Boolean(anchorElShareSortMenu);
  const [showIatfFinal, setShowIatfFinal] = useState<boolean>(true)
  const [showIatf, setShowIatf] = useState<boolean>(true)
  const [showPrenhez, setShowPrenhez] = useState<boolean>(true)
  const printRef1 = useRef(null);
  const printRef2 = useRef(null);
  const printRef3 = useRef(null);
  const printRef4 = useRef(null);
  const printRef5 = useRef(null);
  const [farmName, setFarmName] = useState<string>("");
  const [filtersPrintRef, setFiltersPrintRef] = useState<FiltersPrintRefType | undefined>(undefined);
  const [isPrinting, setIsPrinting] = useState<boolean>(false)

  const mesEnum = useMemo(() => translations ? [
    translations["lbl.react.sem.mes"],
    translations["lbl.react.jan"],
    translations["lbl.react.fev"],
    translations["lbl.react.mar"],
    translations["lbl.react.abr"],
    translations["lbl.react.mai"],
    translations["lbl.react.jun"],
    translations["lbl.react.jul"],
    translations["lbl.react.ago"],
    translations["lbl.react.set"],
    translations["lbl.react.out"],
    translations["lbl.react.nov"],
    translations["lbl.react.dez"],
  ] : [], [translations]);

  const formatStringNumber = (value: string) => {
    return parseFloat(value.replace('%', '').replace(',', '.'));
  }

  const handleAnimalsByConceptions = (animalList: Animal[] | any) => {
    const groupedAnimals = animalList.reduce((group: any, reproduction: Reproduction) => {
      if (reproduction && reproduction.cobPrenhezData) {
        group[reproduction.cobPrenhezData.split('-')[0]+reproduction.cobPrenhezData.split('-')[1]] = group[reproduction.cobPrenhezData.split('-')[0]+reproduction.cobPrenhezData.split('-')[1]] || [];
        group[reproduction.cobPrenhezData.split('-')[0]+reproduction.cobPrenhezData.split('-')[1]].push(reproduction);
        return group;
      } else {
        return [];
      }
    }, {})
    const groupedAnimalsSorted = Object.entries(groupedAnimals).sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    return groupedAnimalsSorted
  }

  const handleTableData = useCallback(() => {
    if (reproductionsConceptions.length) {
      const animalsList = reproductionsConceptions.filter((reproduction) => {
        if (reproduction && reproduction.cobPrenhezData) {
          return (selectedMonths.includes(mesEnum[parseInt(reproduction.cobPrenhezData.substring(5,7))]+' - '+reproduction.cobPrenhezData.substring(2,4)))
        } else {
          return [];
        }
      })
    
      setSelectedAnimalsByConceptions(animalsList)
    }

  }, [reproductionsConceptions, selectedMonths, mesEnum])

  const handleSelectBars = (barData: any) => {
    if (barData != null) {
      const { activeLabel } = barData;
      const monthOnList = selectedMonths.some(month => month === activeLabel);
      let monthsList: string[] = []
      if (!monthOnList) {
        monthsList = [...selectedMonths, activeLabel].sort((a, b) => {
          const yearMonthA = parseInt((a.split("-")[1]).trim() + (mesEnum.indexOf((a.split("-")[0]).trim()) < 10 ? "0"+String(mesEnum.indexOf((a.split("-")[0]).trim())) : mesEnum.indexOf((a.split("-")[0]).trim())));
          const yearMonthB = parseInt((b.split("-")[1]).trim() + (mesEnum.indexOf((b.split("-")[0]).trim()) < 10 ? "0"+String(mesEnum.indexOf((b.split("-")[0]).trim())) : mesEnum.indexOf((b.split("-")[0]).trim())));
          return yearMonthA - yearMonthB
        })
        setSelectedMonths(monthsList)
      }
    }
  }

  const handleDelete = (label: string) => {
    if (selectedMonths.length > 1) {
      const monthsList = selectedMonths.filter(months => months !== label)
      setSelectedMonths(monthsList)
    }
  };
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_event: any) => {
    setAnchorEl(null);
  };

  const handleClickConceptionSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElConceptionSortMenu(event.currentTarget);
  };

  const handleCloseConceptionSortMenu = (_event: any) => {
    setAnchorElConceptionSortMenu(null);
  };

  const handleClickShareSortMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElShareSortMenu(event.currentTarget);
  };

  const handleCloseShareSortMenu = (_event: any) => {
    setAnchorElShareSortMenu(null);
  };

  const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
    setReproductions([])
    if (value === "concepcao") setDatePlaceholder(translations["lbl.react.filtro.data.cobertura"])
    else setDatePlaceholder(translations["lbl.react.filtro.data.cobertura"])
    setSelectedTab(value);
  };

  const handleModalConcepts = () => {
    setModalConceptsOpen(!modalConceptsOpen)
  }

  const handleGetAnimals = useCallback(async (filters: FiltersType) => {
    setLoading(true)

    const proCodigo = searchParams.get('pro_codigo') || "";
    const paramsReproducoes = {
      proCodigo: proCodigo,
      dataIni: filters.startDate,
      dataFim: filters.endDate,
      espCodigo: filters.espCodigo,
      racCodigo: filters.racCodigo,
      sexo: filters.sexo,
      lotCodigo: filters.lotCodigo,
      catCodigo: filters.catCodigo,
      statusCria: filters.statusCria,
      considerarDescartados: filters.considerarDescartados,
      cobTipo: filters.cobTipo,
    }

    const paramsFemeasAptas = {
      proCodigo: proCodigo,
      dataIni: filters.startDate,
      dataFim: filters.endDate,
      espCodigo: filters.espCodigo,
      racCodigo: filters.racCodigo,
      lotCodigo: filters.lotCodigo,
      catCodigo: filters.catCodigo,
      statusCria: filters.statusCria,
      considerarDescartados: filters.considerarDescartados,
    }

    const token = searchParams.get('token')

    const resultPrenhas = selectedTab === "prenhez" &&  await getPrenhas(paramsReproducoes, token);

    if (selectedTab === "concepcao") {
      const resultReproducoes = await getReproducoes(paramsReproducoes, token);
      const resultFemeasAptas = await getFemeasAptas(paramsFemeasAptas, token);
      const farmsResult = await getPropriedades(proCodigo, token).then((data: any) => data.filter((currFarm: FarmsType) => currFarm.proCodigo === Number(proCodigo))[0].proDescricao);
      const femeasAptasInReproductionFormat: Reproduction[] = [];

      if (resultFemeasAptas && resultReproducoes && resultFemeasAptas.status === 200) {
        // Junta as fêmeas aptas sem cobertura às cobertas 
        let listOfAniCodigos = resultReproducoes.map((reproduction: Reproduction) => reproduction.animal.aniCodigo);
        if (resultFemeasAptas.data != null) {
          resultFemeasAptas.data.forEach((femeaApta: FemeaApta) => {
            if (!listOfAniCodigos.includes(femeaApta.aniCodigo)) {
              let femeaAptaInReproductionFormat = {
                cobCodigo: femeaApta.reproducaoData.cobCodigo,
                cobData: femeaApta.reproducaoData.cobData,
                cobDataToque: femeaApta.reproducaoData.cobDataToque,
                cobDataToqueN2: femeaApta.reproducaoData.cobDataToqueN2,
                cobDataToqueN3: femeaApta.reproducaoData.cobDataToqueN3,
                cobIndN2: femeaApta.reproducaoData.cobIndN2,
                cobIndN3: femeaApta.reproducaoData.cobIndN3,
                cobDataRt: femeaApta.reproducaoData.cobDataRt,
                cobIndRt: femeaApta.reproducaoData.cobIndRt,
                cobIndStatus: femeaApta.reproducaoData.cobIndStatus,
                cobIndToque: femeaApta.reproducaoData.cobIndToque,
                animal: {
                  aniCodigo: femeaApta.aniCodigo,
                  idVisual: femeaApta.idVisual,
                  idEletronico: femeaApta.idEletronico,
                  dataNascimento: femeaApta.dataNascimento,
                  peso: femeaApta.peso,
                  lote: femeaApta.lote,
                  status: femeaApta.status,
                  statusDescarte: femeaApta.statusDescarte,
                  aniSexo: "F",
                  aniNumeroCab: "1",
                },
                categoria: {
                    catCodigo: femeaApta.categoriaData.catCodigo,
                    catNome: femeaApta.categoriaData.catNome,
                },
                raca: {
                    espCodigo: femeaApta.racaData.espCodigo,
                    espNome: femeaApta.racaData.espNome,
                    racCodigo: femeaApta.racaData.racCodigo,
                    racNome: femeaApta.racaData.racNome,
                }
              };
  
              femeasAptasInReproductionFormat.push(femeaAptaInReproductionFormat);
            }
          });
        }
      }
  
      let resultData : Reproduction[] = [];
  
      if (resultReproducoes) {
        resultData = [...resultReproducoes];
      }
      if (femeasAptasInReproductionFormat && resultData) {
        resultData = [...resultData, ...femeasAptasInReproductionFormat];
      }
      if (femeasAptasInReproductionFormat && !resultData) {
        resultData = [...femeasAptasInReproductionFormat];
      }

      if (filters.considerarDescartados && resultData) {
        setReproductions([...resultData]);
      } else if (!filters.considerarDescartados && resultData) {
        setReproductions([...resultData.filter((reproduction: Reproduction) => (!reproduction.animal.statusDescarte))]);
      }
  
      setFarmName(farmsResult);

    } else if (selectedTab === "prenhez" && resultPrenhas && translations){
      const groupedAnimaisByDate = handleAnimalsByConceptions(resultPrenhas);
      setReproductionsConceptions(resultPrenhas);

      const mappedData = groupedAnimaisByDate.map((group: Animal[] | any) => (
        {
          "xData": mesEnum[parseInt(group[0].substring(4,6))]+' - '+group[0].substring(2,4),
          "yData": group[1].length,
          "rawData": group[1],
          "name": translations["lbl.react.animais"],
          "color": "#607d8b"
        }
      ))
      const months = mappedData ? mappedData.map((data: { xData: string; }) => data.xData) : [];
      
      setSelectedMonths(months)
      setBarChartDataByConceptions(mappedData);
    }

    setLoading(false);
  }, [mesEnum, searchParams, selectedTab, translations]);

  const handleSortButton = (sortOption: string, defaultOrderOption: string, chart: string) => {
    let usedChartData = chart === 'conception' ? barChartDataBySemen : barChartDataBySemenTotal;
    let usedSetChartData = chart === 'conception' ? setBarChartDataBySemen : setBarChartDataBySemenTotal;
    let usedSummaryData = chart === 'conception' ? sumaryIatfBySemenData : sumaryIatfTotalBySemenData;
    let usedSetSummaryData = chart === 'conception' ? setSumaryIatfBySemenData : setSumaryIatfTotalBySemenData;
    let usedSortConfig = chart === 'conception' ? selectedConceptionSortConfig : selectedShareSortConfig;
    let usedSetSortConfig = chart === 'conception' ? setSelectedConceptionSortConfig : setSelectedShareSortConfig;
    let updatedReference = '';
    let updatedOrder = '';
    if (usedSortConfig.reference === sortOption) { 
      updatedReference = usedSortConfig.reference;
      updatedOrder = usedSortConfig.order === 'asc' ? 'desc' : 'asc';
    } else {
      updatedReference = sortOption;
      updatedOrder = defaultOrderOption;
    }

    if (updatedReference === 'alphabetical') {
      if (updatedOrder === 'asc') {
        usedSetChartData(usedChartData.sort((a: any, b: any) => String(a.xData).localeCompare(String(b.xData))));
        usedSetSummaryData((prevState) => ({...prevState, tableRows: usedSummaryData.tableRows.sort((a: any, b: any) => String(a[0]).localeCompare(String(b[0])))}));
      } else {
        usedSetChartData(usedChartData.sort((a: any, b: any) => String(b.xData).localeCompare(String(a.xData))));
        usedSetSummaryData((prevState) => ({...prevState, tableRows: usedSummaryData.tableRows.sort((a: any, b: any) => String(b[0]).localeCompare(String(a[0])))}));
      }
    } else if (updatedReference === 'byValue') {
      if (updatedOrder === 'asc') {
        usedSetChartData(usedChartData.sort((a: any, b: any) => a.yData - b.yData));
        usedSetSummaryData((prevState) => ({...prevState, tableRows: usedSummaryData.tableRows.sort((a: any, b: any) => formatStringNumber(a[chart === 'conception' ? 3 : 2]) - formatStringNumber(b[chart === 'conception' ? 3 : 2]))}));
      } else {
        usedSetChartData(usedChartData.sort((a: any, b: any) => b.yData - a.yData));
        usedSetSummaryData((prevState) => ({...prevState, tableRows: usedSummaryData.tableRows.sort((a: any, b: any) => formatStringNumber(b[chart === 'conception' ? 3 : 2]) - formatStringNumber(a[chart === 'conception' ? 3 : 2]))}));
      }
    }
    
    usedSetSortConfig({
      reference: updatedReference,
      order: updatedOrder
    });
    handleCloseShareSortMenu(null);
    handleCloseConceptionSortMenu(null);
  }

  const handleSearchButton = (filters: FiltersType) => {
    const mappedFilters = {
      ...filters,
      startDate: filters.startDate ? dayjs(filters.startDate).format("YYYY-MM-DD") : "",
      endDate: filters.endDate
      ? (selectedTab === "concepcao" ? dayjs(filters.endDate).format("YYYY-MM-DD") : dayjs(filters.endDate).add(1, 'months').subtract(1, 'days').format("YYYY-MM-DD"))
      : ""
    }

    handleGetAnimals(mappedFilters);
    const calculateInterval = (dayjs(filters.endDate).diff(filters.startDate, "days")) + 1;
    setDaysInterval(calculateInterval ? calculateInterval : 365);
  }

  const handleCloseTable = (name: string) => {
    if (name === "prenhez") setSelectedMonths([])
  }

  useEffect(() => {
    if (reproductions != null && translations) {
      const mappedData = [
        {
          "xData": translations["lbl.react.taxa.concepcao.1.iatf"],
          "yData": separateByIatf1(reproductions).percentage,
          "rawData": separateByIatf1(reproductions).animals,
          "name": 'Porcentagem',
          "color": "#607d8b"
        },
        {
          "xData": translations["lbl.react.taxa.concepcao.2.iatf"],
          "yData": separateByIatf2(reproductions).percentage,
          "rawData": separateByIatf2(reproductions).animals,
          "name": 'Porcentagem',
          "color": "#607d8b"
        },
        {
          "xData": translations["lbl.react.taxa.concepcao.3.iatf"],
          "yData": separateByIatf3(reproductions).percentage,
          "rawData": separateByIatf3(reproductions).animals,
          "name": 'Porcentagem',
          "color": "#607d8b"
        },
      ]

      if (showIatf) mappedData.push({
        "xData": translations["lbl.react.taxa.concepcao.iatf"],
        "yData": separateByIatfTotal(reproductions).percentage,
        "rawData": separateByIatfTotal(reproductions).animals,
        "name": 'Porcentagem',
        "color": "#607d8b"
      })
  
      if (showIatfFinal) mappedData.push({
        "xData": translations["lbl.react.taxa.concepcao.final"],
        "yData": separateByIatfTotalRepasse(reproductions).percentage,
        "rawData": separateByIatfTotalRepasse(reproductions).animals,
        "name": 'Porcentagem',
        "color": "#084337"
      })

      if (showPrenhez) mappedData.push({
        "xData": translations["lbl.react.taxa.prenhez"],
        "yData": separateByPrenhez(reproductions).percentage,
        "rawData": separateByPrenhez(reproductions).animals,
        "name": 'Porcentagem',
        "color": "#084337"
      })

      const separateIatfBySemenData = Object.entries(separateIatfBySemen(reproductions));
      const separateConceptionsBySemenData = separateConceptionsBySemen(reproductions);
      
      interface GenericTablePaginationProps {
        tableHeads: (string | number)[] | [];
        tableUpperHeads?: (string | number)[] | [];
        tableRows: Array<Array<string | number>> | [[]];
        tableTotalizer?: Array<Array<string | number>>;
        tableHeadsTooltips?: {[key: number]: string};
        tableTotalizerTooltips?: {[key: number]: string};
      }

      let semenSummaryData:GenericTablePaginationProps = { tableHeads: [], tableRows: [[]], tableHeadsTooltips: {} };
      let semenTotalSummaryData:GenericTablePaginationProps = { tableHeads: [], tableRows: [[]] };
      let iatfXSemenSummaryData:GenericTablePaginationProps = { tableHeads: [], tableRows: [[]] };

      if (separateIatfBySemenData.length > 0) {  
        if (translations) {
          semenSummaryData['tableHeads'] = [
            translations["lbl.react.tabela.semen"],
            translations["lbl.react.tabela.iatfs"],
            translations["lbl.react.tabela.prenhas"],
            translations["lbl.react.tabela.taxa.de.concepcao"]
          ];

          semenSummaryData['tableHeadsTooltips'] = {3: translations["lbl.react.tooltip.taxa.concepcoes.por.semen"]};

          semenTotalSummaryData['tableHeads'] = [
            translations["lbl.react.tabela.semen"],
            translations["lbl.react.tabela.prenhas"],
            translations["lbl.react.participacao.semen.concepcoes"]
          ];

          semenTotalSummaryData['tableHeadsTooltips'] = {2: translations["lbl.react.tooltip.numero.femeas.prenhas.determinado.semen"] + ' (' + separateByIatfTotal(reproductions).conceptionTotal + '), ' + translations["lbl.react.tooltip.multiplicado.por.cem.porcentagem"]};

          iatfXSemenSummaryData['tableTotalizerTooltips'] = {
            3: translations["lbl.react.tooltip.total.tx.conc.1.iatf"],
            6: translations["lbl.react.tooltip.total.tx.conc.2.iatf"],
            9: translations["lbl.react.tooltip.total.tx.conc.3.iatf"]
          };

          iatfXSemenSummaryData['tableHeads'] = [
            translations["lbl.react.tabela.semen"],
            translations["lbl.react.tabela.iatfs"],
            translations["lbl.react.tabela.prenhas"],
            translations["lbl.react.tx.conc"],
            translations["lbl.react.tabela.iatfs"],
            translations["lbl.react.tabela.prenhas"],
            translations["lbl.react.tx.conc"],
            translations["lbl.react.tabela.iatfs"],
            translations["lbl.react.tabela.prenhas"],
            translations["lbl.react.tx.conc"]
          ];

          iatfXSemenSummaryData['tableUpperHeads'] = [
            '',
            '',
            translations["lbl.react.tabela.primeira.iatf"],
            '',
            '',
            translations["lbl.react.tabela.segunda.iatf"],
            '',
            '',
            translations["lbl.react.tabela.terceira.iatf"],
            '',
          ];

          setFiltersPrintRef({
            'espCodigo': translations["lbl.react.filter.especie"],
            'racCodigo': translations["lbl.react.filter.raca"],
            'catCodigo': translations["lbl.react.filter.categoria"],
            'lotCodigo': translations["lbl.react.filter.lote"],
          });
        }

        let separatedTableRows = separateIatfBySemenData.map(([semen, iatfs]: [string, any]) => {
          return ([
            semen,
            iatfs.length,
            separateConceptionsBySemenData[semen] ? separateConceptionsBySemenData[semen].length : 0,
            separateConceptionsBySemenData[semen] ? parseFloat((separateConceptionsBySemenData[semen].length / (iatfs as string[]).length * 100).toFixed(2)).toString().replace(".", ",") + '%' : '0%',
          ])
        });

        let separatedTotalTableRows = separateIatfBySemenData.map(([semen, _iatfs]: [string, any]) => {
          return ([
            semen,
            separateConceptionsBySemenData[semen] ? separateConceptionsBySemenData[semen].length : 0,
            separateConceptionsBySemenData[semen] ? parseFloat((separateConceptionsBySemenData[semen].length / separateByIatfTotal(reproductions).conceptionTotal * 100).toFixed(2)).toString().replace(".", ",") + '%' : '0%',
          ])
        });

        separatedTableRows.sort((a, b) => String(a[0]).localeCompare(String(b[0])));
        separatedTotalTableRows.sort((a, b) => String(a[0]).localeCompare(String(b[0])));
        semenSummaryData['tableRows'] = separatedTableRows;
        semenTotalSummaryData['tableRows'] = separatedTotalTableRows;

        setSumaryIatfBySemenData((prevData) => ({
          ...prevData,
          tableHeads: semenSummaryData.tableHeads,
          tableRows: semenSummaryData.tableRows,
          tableHeadsTooltips: semenSummaryData.tableHeadsTooltips
        }));

        setSumaryIatfTotalBySemenData((prevData) => ({
          ...prevData,
          tableHeads: semenTotalSummaryData.tableHeads,
          tableRows: semenTotalSummaryData.tableRows,
          tableHeadsTooltips: semenTotalSummaryData.tableHeadsTooltips
        }));

        let mappedDataBySemen = separateIatfBySemenData.map((semen: [string, any]) => {
          return ({
            "xData": semen[0],
            "yData": separateConceptionsBySemenData[semen[0]] ? parseFloat((separateConceptionsBySemenData[semen[0]].length / semen[1].length * 100).toFixed(2)) : 0,
            "rawData": semen[1],
            "name": translations["lbl.react.tabela.porcentagem"],
            "color": "#607d8b"
          })
        }).sort((a, b) => String(a.xData).localeCompare(String(b.xData))).filter((chartData) => chartData.yData !== 0);

        let mappedDataBySemenTotal = separateIatfBySemenData.map((semen: [string, any]) => {
          return ({
            "xData": semen[0],
            "yData": separateConceptionsBySemenData[semen[0]] ? parseFloat((separateConceptionsBySemenData[semen[0]].length / separateByIatfTotal(reproductions).conceptionTotal * 100).toFixed(2)) : 0,
            "rawData": semen[1],
            "name": translations["lbl.react.tabela.porcentagem"],
            "color": "#607d8b"
          })
        }).sort((a, b) => String(a.xData).localeCompare(String(b.xData))).filter((chartData) => chartData.yData !== 0);

        setBarChartDataBySemen(mappedDataBySemen);
        setBarChartDataBySemenTotal(mappedDataBySemenTotal);
      }

      const separatedIatfsByPhaseAndSemen = separateIatfsByPhaseAndSemen(reproductions);

      const separatedIatf1BySemenEntries: any = Object.entries(separatedIatfsByPhaseAndSemen.iatf1);
      const separatedIatf2BySemenEntries: any = Object.entries(separatedIatfsByPhaseAndSemen.iatf2);
      const separatedIatf3BySemenEntries: any = Object.entries(separatedIatfsByPhaseAndSemen.iatf3);

      const extractSemenNames = (semenData: any): string[] => {
        let extractedNames: string[] = [];
        
        for (let i = 0; i < semenData.length; i++) {
          extractedNames.push(semenData[i][0]);
        }

        return extractedNames;
      }
  
      const semenNames = Array.from(new Set([
        ...extractSemenNames(separatedIatf1BySemenEntries), 
        ...extractSemenNames(separatedIatf2BySemenEntries), 
        ...extractSemenNames(separatedIatf3BySemenEntries)
      ]));

      const aggregateSemenNames = (semensList: string[], semensData: any) => {
        let result: any = {};

        for (let i = 0; i < semensList.length; i++) {
          if (semensData.find((semen: any) => semen[0] === semensList[i])) {
            result[semensList[i]] = semensData.find((semen: any) => semen[0] === semensList[i])[1];
          } else {
            result[semensList[i]] = [];
          }
        }

        return result;
      }

      const separatedIatf1BySemenAggregateData = aggregateSemenNames(semenNames, separatedIatf1BySemenEntries);
      const separatedIatf2BySemenAggregateData = aggregateSemenNames(semenNames, separatedIatf2BySemenEntries);
      const separatedIatf3BySemenAggregateData = aggregateSemenNames(semenNames, separatedIatf3BySemenEntries);
      const separatedConceptionsIatf1BySemenData = separateConceptionsBySemen(separateByIatf1(reproductions).animals);
      const separatedConceptionsIatf2BySemenData = separateConceptionsBySemen(separateByIatf2(reproductions).animals);
      const separatedConceptionsIatf3BySemenData = separateConceptionsBySemen(separateByIatf3(reproductions).animals);

      if (separatedIatf1BySemenAggregateData && Object.entries(separatedIatf1BySemenAggregateData).length > 0) {
        let mappedDataBySemenIatf1 = Object.entries(separatedIatf1BySemenAggregateData).map((semen: [any, any]) => {
          return ({
            "yData": `${semen[0]} - ${separatedConceptionsIatf1BySemenData[semen[0]] ? parseFloat((separatedConceptionsIatf1BySemenData[semen[0]].length / separatedIatf1BySemenAggregateData[semen[0]].length * 100).toFixed(2)) : 0}`,
            "xData": separatedConceptionsIatf1BySemenData[semen[0]] ? parseFloat((separatedConceptionsIatf1BySemenData[semen[0]].length / separatedIatf1BySemenAggregateData[semen[0]].length * 100).toFixed(2)) : 0,
            "rawData": semen[1],
            "name": translations["lbl.react.tabela.porcentagem"],
            "color": "#607d8b"
          })
        }).sort((a: any, b: any) => String(a.yData).localeCompare(String(b.yData)));

        setBarChartDataBySemenIatf1(mappedDataBySemenIatf1);
      }
      
      if (separatedIatf2BySemenAggregateData && Object.entries(separatedIatf2BySemenAggregateData).length > 0) {
        let mappedDataBySemenIatf2 = Object.entries(separatedIatf2BySemenAggregateData).map((semen: [any, any]) => {
          return ({
            "yData": `${semen[0]} - ${separatedConceptionsIatf2BySemenData[semen[0]] ? parseFloat((separatedConceptionsIatf2BySemenData[semen[0]].length / separatedIatf2BySemenAggregateData[semen[0]].length * 100).toFixed(2)) : 0}`,
            "xData": separatedConceptionsIatf2BySemenData[semen[0]] ? parseFloat((separatedConceptionsIatf2BySemenData[semen[0]].length / separatedIatf2BySemenAggregateData[semen[0]].length * 100).toFixed(2)) : 0,
            "rawData": semen[1],
            "name": translations["lbl.react.tabela.porcentagem"],
            "color": "#607d8b"
          })
        }).sort((a: any, b: any) => String(a.yData).localeCompare(String(b.yData)));

        setBarChartDataBySemenIatf2(mappedDataBySemenIatf2);
      }

      if (separatedIatf3BySemenAggregateData && Object.entries(separatedIatf3BySemenAggregateData).length > 0) {
        let mappedDataBySemenIatf3 = Object.entries(separatedIatf3BySemenAggregateData).map((semen: [any, any]) => {
          return ({
            "yData": `${semen[0]} - ${separatedConceptionsIatf3BySemenData[semen[0]] ? parseFloat((separatedConceptionsIatf3BySemenData[semen[0]].length / separatedIatf3BySemenAggregateData[semen[0]].length * 100).toFixed(2)) : 0}`,
            "xData": separatedConceptionsIatf3BySemenData[semen[0]] ? parseFloat((separatedConceptionsIatf3BySemenData[semen[0]].length / separatedIatf3BySemenAggregateData[semen[0]].length * 100).toFixed(2)) : 0,
            "rawData": semen[1],
            "name": translations["lbl.react.tabela.porcentagem"],
            "color": "#607d8b"
          })
        }).sort((a: any, b: any) => String(a.yData).localeCompare(String(b.yData)));

        setBarChartDataBySemenIatf3(mappedDataBySemenIatf3);

        if (separatedIatf1BySemenAggregateData) {
          let mappedIatf123XSemenSummaryData = semenNames.map((semenName: any) => {
            let iatfs1 = separatedIatf1BySemenAggregateData[semenName].length;
            let conceptions1Iatf = separatedConceptionsIatf1BySemenData[semenName] ? separatedConceptionsIatf1BySemenData[semenName].length : 0;
            let iatfs2 = separatedIatf2BySemenAggregateData[semenName].length;
            let conceptions2Iatf = separatedConceptionsIatf2BySemenData[semenName] ? separatedConceptionsIatf2BySemenData[semenName].length : 0;
            let iatfs3 = separatedIatf3BySemenAggregateData[semenName].length;
            let conceptions3Iatf = separatedConceptionsIatf3BySemenData[semenName] ? separatedConceptionsIatf3BySemenData[semenName].length : 0;

            return [
              semenName,
              iatfs1,
              conceptions1Iatf,
              conceptions1Iatf > 0 ? parseFloat((conceptions1Iatf / iatfs1 * 100).toFixed(2)).toString().replace('.', ',') + '%' : 0,
              iatfs2,
              conceptions2Iatf,
              conceptions2Iatf > 0 ? parseFloat((conceptions2Iatf / iatfs2 * 100).toFixed(2)).toString().replace('.', ',') + '%' : 0,
              iatfs3,
              conceptions3Iatf,
              conceptions3Iatf > 0 ? parseFloat((conceptions3Iatf / iatfs3 * 100).toFixed(2)).toString().replace('.', ',') + '%' : 0
            ]
          }).sort((a: any, b: any) => String(a[0]).localeCompare(String(b[0])));

          let total1IatfSumaryData = separateByIatf1(reproductions);
          let total2IatfSumaryData = separateByIatf2(reproductions);
          let total3IatfSumaryData = separateByIatf3(reproductions);

          let totalizerIatfXSemenSummaryData = [
            'TOTAL',
            mappedIatf123XSemenSummaryData.reduce((acc: any, curr: any) => acc + curr[1], 0),
            mappedIatf123XSemenSummaryData.reduce((acc: any, curr: any) => acc + curr[2], 0),
            total1IatfSumaryData.percentage.toFixed(2).replace(".", ",") + "%",
            mappedIatf123XSemenSummaryData.reduce((acc: any, curr: any) => acc + curr[4], 0),
            mappedIatf123XSemenSummaryData.reduce((acc: any, curr: any) => acc + curr[5], 0),
            total2IatfSumaryData.percentage.toFixed(2).replace(".", ",") + "%",
            mappedIatf123XSemenSummaryData.reduce((acc: any, curr: any) => acc + curr[7], 0),
            mappedIatf123XSemenSummaryData.reduce((acc: any, curr: any) => acc + curr[8], 0),
            total3IatfSumaryData.percentage.toFixed(2).replace(".", ",") + "%",
          ];

          setSumaryIatfXSemenData((prevData) => ({
            ...prevData,
            tableHeads: iatfXSemenSummaryData.tableHeads,
            tableUpperHeads: iatfXSemenSummaryData.tableUpperHeads,
            tableRows: mappedIatf123XSemenSummaryData,
            tableHeadsTooltips: iatfXSemenSummaryData.tableHeadsTooltips,
            tableTotalizer: totalizerIatfXSemenSummaryData,
            tableTotalizerTooltips: iatfXSemenSummaryData.tableTotalizerTooltips
          }));
        }
      }

      if (selectedTab === "concepcao") setBarChartDataByIatf(mappedData)
    }
  }, [reproductions, selectedTab, showIatf, showIatfFinal, showPrenhez, translations])

  useEffect(() => {

      handleGetAnimals({
        startDate: '',
        endDate: '',
        espCodigo: '',
        racCodigo: '',
        sexo: '',
        lotCodigo: '',
        catCodigo: '',
        statusCria: '',
        cobTipo: ''
      });

  }, [handleGetAnimals]);

  useEffect(() => {
    if (selectedTab === "prenhez") {
      handleTableData()
    }

  }, [handleTableData, selectedTab, translations]);

  useEffect(() => {

    const initialTab = searchParams.get('indicador') || "concepcao"
    const languageParam = searchParams.get('language') || 'pt'
    setLanguage(languageParam)
    setSelectedTab(initialTab)

  }, [searchParams, setLanguage]);

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title">
              { translations["lbl.react.indicadores.prenhez"] }
            </span>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={12}>
                { selectedTab &&
                  <Tabs handleChange={handleChangeTab} value={selectedTab} labels={[{ label: showPrenhez ? translations["lbl.react.aba.tx.concepcao.e.prenhez"] : translations["lbl.react.aba.tx.concepcao"], name: "concepcao" }, { label: translations["lbl.react.aba.prenhas"], name: "prenhez" }]}/>
                }
              </Grid>
              <Grid item xs={12}>
                <Filter
                  handleSearch={handleSearchButton}
                  datePlaceholder={datePlaceholder}
                  selectedTab={selectedTab}
                  showFilters={selectedTab === "concepcao" ? showFilters.prenhez : showFilters.prenhas}
                  onlyMonths={selectedTab === "concepcao" ? false : true}
                  dateFormat={selectedTab === "concepcao" ? "dd/MM/yyyy" : "MM/yyyy"}
                  dateTooltip={ translations["lbl.react.tooltip.filtra.intervalo.coberturas"] }
                  considerarDescartados={considerarDescartados}
                  handleConsiderarDescartados={setConsiderarDescartados}
                />
              </Grid>
              { loading === false ?
                <Grid item xs={12}>
                  {selectedTab === "prenhez" && (
                    reproductionsConceptions.length ?
                    <div>
                      <PageForPrint reference={printRef5} farmName={farmName} chartTitle={translations["lbl.react.aba.prenhas"]} usedFilters={filtersPrintRef}>
                        <Grid container className="chart-container">
                          <Grid item xs={12}>
                            <AccordionCustom
                              title={translations["lbl.react.aba.prenhas"]}
                              subtitle={translations["lbl.react.clique.meses.visualizar.tabela"]}
                              amountText={translations["lbl.react.prenhas.intervalo"]}
                              intervalTotal={barChartDataByConceptions ? barChartDataByConceptions.reduce((acc: number, previous: BarChartData) => acc = acc + previous.yData, 0) : 0}
                              topButtons={<Printer componentRef={printRef5} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.aba.prenhas"]} />}
                            >
                              <Grid container item xs={12} pb={2} mb={3}>
                                <BarChart
                                  data={barChartDataByConceptions}
                                  barLabel={translations["lbl.react.animais"]}
                                  sideLabel={translations["lbl.react.quantidade.animais"]}
                                  handleSelectBar={handleSelectBars}
                                />
                              </Grid>
                            </AccordionCustom>
                          </Grid>
                          { selectedMonths.length > 0 &&
                            <Box sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px", margin: "0 16px 16px 16px"} }>
                              <Grid item container p={2}>
                                <Grid item xs={12} mb={2}>
                                  <span className="c-b-cinza-icons chart-warning">{translations["lbl.react.obs.para.ocultar.dados.meses.tabela"]}</span>
                                </Grid>
                                <Grid item xs={12}>
                                  <span>{translations["lbl.react.meses.selecionados"]}</span>
                                </Grid>
                                <Grid item container xs={12} mt={2} columnSpacing={2}>
                                  {
                                    selectedMonths.map((month) => <Badge label={month} handleDelete={handleDelete} key={month} deletable={selectedMonths.length !== 1} />)
                                  }
                                </Grid>
                              </Grid>
                            </Box>
                          }
                        </Grid>
                      </PageForPrint>
                      { selectedMonths.length > 0 &&
                        <Table
                          columns={fieldsTables(translations).prenhas}
                          rows={selectedAnimalsByConceptions}
                          idColumn='cobCodigo'
                          sortColumn='cobCodigo'
                          tableTitle={translations["lbl.react.dados.para.meses.selecionados"]}
                          handleCloseTable={handleCloseTable}
                          name="previsao"
                        />
                      }
                    </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                  {selectedTab === "concepcao" && (
                    reproductions.length ?
                      <div>
                        <PageForPrint reference={printRef1} farmName={farmName} chartTitle={showPrenhez ? translations["lbl.react.taxa.concepcao.por.iatf.e.prenhez"] : translations["lbl.react.taxa.concepcao.por.iatf"]} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container">
                            <Grid item xs={12}>
                              <AccordionCustom
                                  title={showPrenhez ? translations["lbl.react.taxa.concepcao.por.iatf.e.prenhez"] : translations["lbl.react.taxa.concepcao.por.iatf"]}
                                  subtitle=""
                                  amountText=""
                                  intervalTotal={0}
                                  topButtons={
                                    <Grid item container justifyContent="end" alignItems="center" wrap='nowrap' flexDirection={"row"} gap={2} className="hide-to-print">
                                      <Printer componentRef={printRef1} setIsPrinting={setIsPrinting} chartTitle={showPrenhez ? translations["lbl.react.taxa.concepcao.por.iatf.e.prenhez"] : translations["lbl.react.taxa.concepcao.por.iatf"]} />
                                      <ConceptsModal open={modalConceptsOpen} handleClose={handleModalConcepts}/>
                                      <IconButton aria-label="setting" className="btn-primario btn-print-small hide-to-print" onClick={handleClick}>
                                        <SettingsIcon />
                                      </IconButton>
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                        }}
                                        >
                                        <MenuItem onClick={() => setShowIatf(!showIatf)}><Checkbox checked={showIatf}/>{translations["lbl.react.taxa.concepcao.iatf"]}</MenuItem>
                                        <MenuItem onClick={() => setShowIatfFinal(!showIatfFinal)}><Checkbox checked={showIatfFinal}/>{translations["lbl.react.taxa.concepcao.final"]}</MenuItem>
                                        <MenuItem onClick={() => setShowPrenhez(!showPrenhez)}><Checkbox checked={showPrenhez}/>{translations["lbl.react.taxa.prenhez"]}</MenuItem>
                                      </Menu>
                                    </Grid>
                                  }
                                  >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <Grid container item xs={12} pb={2} mb={3}>
                                    <Grid container item xs={8} mt={3} className="chart-grid">
                                      <BarChart
                                        data={barChartDataByIatf}
                                        barLabel={translations["lbl.react.tooltip.taxa"]}
                                        sideLabel={translations["lbl.react.taxa.concepcao.porcentagem"]}
                                        limiteYAxis
                                        yAxisUnit="%"
                                      />
                                    </Grid>
                                    <Grid item xs minWidth={300} maxWidth={350} className="chart-conception-summary">
                                      <ConceptionSummary
                                        data={reproductions}
                                        daysInterval={daysInterval}
                                        showIatf={showIatf}
                                        showIatfFinal={showIatfFinal}
                                        showPrenhez={showPrenhez}
                                        />
                                    </Grid>
                                    <Grid item xs={4} minWidth={364} className="chart-iatf-summary">
                                      <IatfSummary data={reproductions} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <PageForPrint reference={printRef2} farmName={farmName} chartTitle={translations["lbl.react.taxa.concepcao.por.semen"]} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container" mt={2}>
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.taxa.concepcao.por.semen"]}
                                subtitle=""
                                amountText=""
                                intervalTotal={0}
                                topButtons={
                                  <Grid item container justifyContent="end" alignItems="center" wrap='nowrap' flexDirection={"row"} gap={2} className="hide-to-print">
                                    <IconButton aria-label="setting" className="btn-secundario btn-print-small hide-to-print" onClick={handleClickConceptionSortMenu}>
                                      <ImportExportIcon />
                                    </IconButton>
                                    <Menu
                                      id="sort-menu-sperm"
                                      anchorEl={anchorElConceptionSortMenu}
                                      open={openConceptionSortMenu}
                                      onClose={handleCloseConceptionSortMenu}
                                      MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                      }}
                                      >
                                      <MenuItem style={{fontWeight: selectedConceptionSortConfig.reference === 'alphabetical' ? 'bold' : 'normal'}} onClick={() => handleSortButton('alphabetical', 'asc', 'conception')}>{translations["lbl.react.ordenacao.alfabetica"]}</MenuItem>
                                      <MenuItem style={{fontWeight: selectedConceptionSortConfig.reference === 'byValue' ? 'bold' : 'normal'}} onClick={() => handleSortButton('byValue', 'desc', 'conception')}>{translations["lbl.react.ordenacao.por.taxa.de.concepcao"]}</MenuItem>
                                    </Menu>
                                    <Printer componentRef={printRef2} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.taxa.concepcao.por.semen"]} />
                                  </Grid>
                                }
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <Grid container item xs={12} mt={3} mb={3}>
                                    <BarChart
                                      data={barChartDataBySemen}
                                      barLabel={translations["lbl.react.taxa.concepcao"]}
                                      sideLabel={translations["lbl.react.taxa.concepcao.porcentagem"]}
                                      limiteYAxis
                                      yAxisUnit="%"
                                      indexValues={true}
                                      shortenNames={true}
                                      />
                                  </Grid>
                                  <Grid container item xs={12} pb={2} mb={3} mt={2} justifyContent="center">
                                    <Grid item xs={6} minWidth={364}>
                                      <GenericTablePagination tableHeads={sumaryIatfBySemenData.tableHeads} tableRows={sumaryIatfBySemenData.tableRows} tableHeadsTooltips={sumaryIatfBySemenData.tableHeadsTooltips} expandToPrint={isPrinting} indexValues={true} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <PageForPrint reference={printRef3} farmName={farmName} chartTitle={translations["lbl.react.participacao.semen.concepcoes"]} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container" mt={2}>
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.react.participacao.semen.concepcoes"]}
                                subtitle=""
                                amountText=""
                                intervalTotal={0}
                                topButtons={
                                  <Grid item container justifyContent="end" alignItems="center" wrap='nowrap' flexDirection={"row"} gap={2} className="hide-to-print">
                                    <IconButton aria-label="setting" className="btn-secundario btn-print-small hide-to-print" onClick={handleClickShareSortMenu}>
                                      <ImportExportIcon />
                                    </IconButton>
                                    <Menu
                                      id="sort-menu-sperm"
                                      anchorEl={anchorElShareSortMenu}
                                      open={openShareSortMenu}
                                      onClose={handleCloseShareSortMenu}
                                      MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                      }}
                                      >
                                      <MenuItem style={{fontWeight: selectedShareSortConfig.reference === 'alphabetical' ? 'bold' : 'normal'}} onClick={() => handleSortButton('alphabetical', 'asc', 'share')}>{translations["lbl.react.ordenacao.alfabetica"]}</MenuItem>
                                      <MenuItem style={{fontWeight: selectedShareSortConfig.reference === 'byValue' ? 'bold' : 'normal'}} onClick={() => handleSortButton('byValue', 'desc', 'share')}>{translations["lbl.react.ordenacao.por.participacao"]}</MenuItem>
                                    </Menu>
                                    <Printer componentRef={printRef3} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.react.participacao.semen.concepcoes"]} />
                                  </Grid>
                                }
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <Grid container item xs={12} mt={3} mb={3}>
                                    <BarChart
                                      data={barChartDataBySemenTotal}
                                      barLabel={translations["lbl.react.participacao.semen.concepcoes"]}
                                      sideLabel={translations["lbl.react.participacao.semen.concepcoes.porcentagem"]}
                                      limiteYAxis
                                      yAxisUnit="%"
                                      indexValues={true}
                                      shortenNames={true}
                                    />
                                  </Grid>
                                  <Grid container item xs={12} pb={2} mb={3} mt={2} justifyContent="center">
                                    <Grid item xs={6} minWidth={364}>
                                      <GenericTablePagination tableHeads={sumaryIatfTotalBySemenData.tableHeads} tableRows={sumaryIatfTotalBySemenData.tableRows} tableHeadsTooltips={sumaryIatfTotalBySemenData.tableHeadsTooltips} expandToPrint={isPrinting} indexValues={true} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <PageForPrint reference={printRef4} farmName={farmName} chartTitle={translations["lbl.taxa.concepcao.por.iatf.x.semen"]} usedFilters={filtersPrintRef}>
                          <Grid container className="chart-container" mt={2}>
                            <Grid item xs={12}>
                              <AccordionCustom
                                title={translations["lbl.taxa.concepcao.por.iatf.x.semen"]}
                                subtitle=""
                                amountText=""
                                intervalTotal={0}
                              >
                                <Grid container item xs={12} pb={2} mb={3}>
                                  <div className="button-corner-position">
                                    <Printer componentRef={printRef4} setIsPrinting={setIsPrinting} chartTitle={translations["lbl.taxa.concepcao.por.iatf.x.semen"]} />
                                  </div>
                                  <Grid container item xs={12} mt={3} gap={2} mb={3} paddingX={2} flexWrap={'nowrap'}>
                                    <Grid item className="vertical-chart-container" xs={4} borderRadius={"3px"} bgcolor={"#efefef"}>
                                      <Grid item xs={12} className="vertical-chart-title-container" mt={1} mb={2}>
                                        <span className="vertical-chart-title">
                                          { translations["lbl.react.taxa.de.concepcao.1.iatf"] }
                                        </span>
                                      </Grid>
                                      <BarChartVertical
                                        syncId="iatfXSemen"
                                        data={barChartDataBySemenIatf1}
                                        barLabel={translations["lbl.react.taxa.de.concepcao.1.iatf.x.semen"]}
                                        sideLabel={translations["lbl.react.taxa.semen.concepcoes.porcentagem"]}
                                        limiteYAxis
                                        yAxisUnit="%"
                                      />
                                      <Grid item className="vertical-chart-bottom-description-container" xs={12} mb={2}>
                                        <span className="vertical-chart-bottom-description">
                                          { translations["lbl.react.porcentagem.percentual.em.concepcao"] }
                                        </span>
                                      </Grid>
                                    </Grid>
                                    <Grid item className="vertical-chart-container" xs={4} borderRadius={"3px"} bgcolor={"#efefef"}>
                                      <Grid item xs={12} className="vertical-chart-title-container" mt={1} mb={2}>
                                        <span className="vertical-chart-title">
                                          { translations["lbl.react.taxa.de.concepcao.2.iatf"] }
                                        </span>
                                      </Grid>
                                      <BarChartVertical
                                        syncId="iatfXSemen"
                                        data={barChartDataBySemenIatf2}
                                        barLabel={translations["lbl.react.taxa.de.concepcao.2.iatf.x.semen"]}
                                        sideLabel={translations["lbl.react.taxa.semen.concepcoes.porcentagem"]}
                                        limiteYAxis
                                        yAxisUnit="%"
                                      />
                                      <Grid item className="vertical-chart-bottom-description-container" xs={12} mb={2}>
                                        <span className="vertical-chart-bottom-description">
                                          { translations["lbl.react.porcentagem.percentual.em.concepcao"] }
                                        </span>
                                      </Grid>
                                    </Grid>
                                    <Grid item className="vertical-chart-container" xs={4} borderRadius={"3px"} bgcolor={"#efefef"}>
                                      <Grid item xs={12} className="vertical-chart-title-container" mt={1} mb={2}>
                                        <span className="vertical-chart-title">
                                          { translations["lbl.react.taxa.de.concepcao.3.iatf"] }
                                        </span>
                                      </Grid>
                                      <BarChartVertical
                                        syncId="iatfXSemen"
                                        data={barChartDataBySemenIatf3}
                                        barLabel={translations["lbl.react.taxa.de.concepcao.3.iatf.x.semen"]}
                                        sideLabel={translations["lbl.react.taxa.semen.concepcoes.porcentagem"]}
                                        limiteYAxis
                                        yAxisUnit="%"
                                      />
                                      <Grid item className="vertical-chart-bottom-description-container" xs={12} mb={2}>
                                        <span className="vertical-chart-bottom-description">
                                          { translations["lbl.react.porcentagem.percentual.em.concepcao"] }
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid container item className='sumary-container' xs={12} pb={2} mb={3} mt={2} justifyContent="center">
                                    <Grid item xs={8} minWidth={364}>
                                      <GenericTablePagination tableHeads={sumaryIatfXSemenData.tableHeads} tableRows={sumaryIatfXSemenData.tableRows} tableHeadsTooltips={sumaryIatfXSemenData.tableHeadsTooltips} tableTotalizer={sumaryIatfXSemenData.tableTotalizer} tableTotalizerTooltips={sumaryIatfXSemenData.tableTotalizerTooltips} valueBorderRight={[0, 3, 6, 9]} tableUpperHeads={sumaryIatfXSemenData.tableUpperHeads} expandToPrint={isPrinting} />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionCustom>
                            </Grid>
                          </Grid>
                        </PageForPrint>
                        <Grid>
                          <Table
                            columns={considerarDescartados ? fieldsTables(translations).concepcao : fieldsTables(translations).concepcao.filter((field: any) => (field.field !== "statusDescarte"))}
                            rows={Object.values(reproductions)}
                            idColumn={['animal', 'aniCodigo']}
                            sortColumn='idVisual'
                            tableTitle={translations["lbl.react.dados.informacoes.selecionadas"]}
                            handleCloseTable={handleCloseTable}
                            name="reproducao"
                            showColumns={showColumns.concepcoes}
                          />
                        </Grid>
                      </div>
                    :
                      <div>
                        <EmptyState />
                      </div>
                    )
                  }
                </Grid>
                :
                <Loading />
              }
            </Grid>
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default IndicadoresPrenhez;